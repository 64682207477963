.quicksummarybox {
  padding: 20px;
  background: $white;
  box-shadow: 0px 3px 14px var(--aqua-1);
  border-radius: 25px;
  margin-top: 40px;

  .quicksummarytitle {
    border-bottom: 1px solid var(--gray-2);
    padding: 0px 0 10px;

    span {
      font-size: 20px;
      color: var(--aqua-9);
      font-weight: bold;
    }

    p {
      font-size: 12px;
      color: var(--aqua-1);
    }
  }

  .quicksummarylist {
    .custom-control-input:checked ~ .custom-control-label {
      color: var(--aqua-1) !important;
      &::before {
        border-color: $pink !important;
        background-color: $pink !important;
      }
    }

    .termslink {
      color: $lightblack;
      text-align: left !important;
      font-weight: normal !important;
      list-style: none;
      padding: 0;

      .termscon {
        label {
          font-size: 16px !important;
        }

        @include checkboxcustom;
      }

      p {
        color: $pink;
        display: inline-block;
        padding: 0 0 0 5px;
        margin: 0;
      }

      li {
        border-bottom: 1px solid var(--gray-2);
        padding: 15px 0;
        color: var(--aqua-1);
      }
    }

    table {
      width: 100%;

      tr {
        padding: 10px 0;
        border-bottom: 1px solid var(--gray-2);

        td {
          color: var(--aqua-1);
          font-size: 14px;
          padding: 15px 0;

          &:last-child {
            text-align: right;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.breakdownbox {
  margin-bottom: 60px;
}

.enebaldisable {
  p {
    display: inline-block;
    padding: 0 10px 0;
  }

  span {
    border-radius: 5px;
    padding: 3px 10px;
    border: 1px solid $deadsea;
    text-align: center;
    cursor: pointer;
  }

  .enebal {
    border: 2px solid $deadsea;
  }

  .disable {
    opacity: 0.5;
  }
}

.consentsplan {
  min-height: 100px;
  background: url("../../../Images/floterplanBg.svg");
  background-size: cover;
  border-radius: 10px;
  color: $white;
  padding: 20px;

  b {
    color: $white;
    opacity: 0.6;
    font-weight: 100;
    text-transform: uppercase;
  }

  span {
    font-size: 18px;
    padding: 0 0 5px;
    display: inherit;
    font-weight: bold;
  }

  p {
    font-size: 14px;
    margin-bottom: 0;
    cursor: pointer;

    i {
      padding-left: 10px;
      font-size: 20px;
      font-weight: bold;
      vertical-align: bottom;
      position: absolute;
      right: 20px;
      bottom: 0;
    }
  }

  strong {
    font-size: 24px;
    display: inline-block;
    padding-top: 20px;
  }

  .Breakdownbox {
    border-top: 1px solid rgba($white, 0.4);
    padding: 20px 0 0;
    margin-top: 10px;
  }
}
.customotp .MuiInput-formControl input {
  padding-left: 8px !important;
}
.consentsfrm {
  .modalbtn {
    padding: 0 !important;
  }
  button {
    width: 100%;
  }
}

.summarytitle {
  text-align: left;

  i {
    padding-right: 15px;
  }
}

.quotesaved {
  color: $grass;
  text-align: right;
}
.quotesavemain {
  text-align: right;
  padding: 20px 0 0;
  i {
    color: var(--coral-4);
    padding-right: 5px;
    font-size: 20px;
  }
  span{
    color: var(--aqua-9);
    margin-left: 5px;
  }
}
.summarymain {
  padding-top: 30px;
}

.consentsfrm .custome-input .MuiInput-formControl {
  border-bottom: 1px solid $dugong !important;
  border: 1px solid $dugong;
  border-radius: 10px;
  padding: 20px 0;
}

.resendOtp {
  &:hover {
    cursor: pointer;
  }
}
