.goldplantopbox {
  padding: 20px;
  background: $white;
  box-shadow: 0px 3px 14px var(--aqua-1);
  border-radius: 25px;
  margin-top: 3rem;
  .goldplantitle {
    font-size: 24px;
    color: var(--aqua-9);
    font-weight: bold;
    span {
      color: var(--aqua-9);
      padding-left: 5px;
    }
    p {
      font-size: 12px;
      color: var(--aqua-1);
      margin: 0;
    }
  }
  .goldpricelist {
    text-align: right;
    i {
      vertical-align: text-top;
      color: $cerebralgrey;
      font-size: 14px;
    }
    span {
      font-size: 24px;
      color: var(--aqua-9);
      font-weight: bold;
    }
    p {
      font-size: 12px;
      color: var(--aqua-1);
      padding-right: 15px;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      div[role="presentation"] {
        margin-left: 5px;
      }
    }
  }
}
.goldquicksummarybox {
  padding: 20px;
  background: $white;
  box-shadow: 0px 3px 14px var(--aqua-1);
  border-radius: 25px;
  margin-top: 40px;
  .goldquicksummarytitle {
    border-bottom: 1px solid var(--gray-2);
    padding: 0px 0 10px;
    span {
      font-size: 20px;
      color: var(--aqua-9);
      font-weight: bold;
    }
    p {
      font-size: 12px;
      color: var(--aqua-1);
    }
  }
  .goldquicksummarylist {
    table {
      width: 100%;
      tr {
        padding: 10px 0;
        border-bottom: 1px solid var(--gray-2);
        border-top: 1px solid var(--gray-2);
        td {
          color: var(--aqua-1);
          font-size: 14px;
          padding: 15px 0;

          &:last-child {
            text-align: right;
            font-weight: bold;
          }
        }
        .form-check-inline{
          .form-check-label{
          //   input[type='radio']{
          //     accent-color:#24B4B3;
          // }
      
         
          }

        }
      }
    }
  }
}
.goldlast {
  margin-bottom: 120px;
}
.goldplanfooter {
  @include fixedfooter;
  box-shadow: 0px 3px 14px var(--aqua-1);
  position: fixed;
  .paynow {
    button {
      color: $white;
      border-radius: 50px;
      background: var(--aqua-9);
      font-weight: bold;
      font-size: 14px;
      border-color: var(--aqua-9);
      text-transform:none;
    }
  }
  .paylettrbtn,
  .sharebtn {
    button {
      color: var(--aqua-9);
      border: 1px solid var(--aqua-9);
      background: $white;
      font-weight: bold;
      font-size: 14px;
      border-radius: 50px;
      text-transform: none;
      &:hover {
        background: var(--aqua-9);
        color: $white;
        text-transform: none;
      }
      &:disabled{
        color: #666 !important;
        background-color: #ccc !important;
        border-color: #ccc !important;
      }
    }
  }
  button,
  .button:focus {
    border-radius: 50px;
    padding: 12px 8px;
    border: 1px solid $pink;
    text-align: center;
    text-transform: none;
    width: 100%;
    outline: none;
    font-size: 14px;
  }
}

#permenantaddress{
  // border:2px solid white;
  box-shadow:0 0 0 1px var(--coral-4);
  appearance:none;
  border-radius:10%;
  width:12px;
  height:12px;
  background-color:#fff;
  transition:all ease-in 0.2s;

}
#permenantaddress:checked{
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%278%27 height=%278%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%23fff%27 d=%27M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  margin: auto;
  background-position-x: 2px;
  background-position-y: 2px;
  box-shadow:0 0 0 1px var(--aqua-7);
  background-color: var(--aqua-7);
}
#no{
  border:2px solid white;
  box-shadow:0 0 0 1px var(--coral-4);
  appearance:none;
  border-radius:50%;
  width:12px;
  height:12px;
  background-color:#fff;
  transition:all ease-in 0.2s;

}
#no:checked{
  background-color:var(--aqua-7);
  box-shadow:0 0 0 1px var(--aqua-7);
}

#yes{
  border:2px solid white;
  box-shadow:0 0 0 1px var(--coral-4);
  appearance:none;
  border-radius:50%;
  width:12px;
  height:12px;
  background-color:#fff;
  transition:all ease-in 0.2s;

}
#yes:checked{
  background-color:var(--aqua-7);
  box-shadow:0 0 0 1px var(--aqua-7);
}
