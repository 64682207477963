.paymentreceivedbox {
  @include boxshow;
  margin-top: 110px;
  text-align: center;
  span {
    width: 100%;
    display: inline-block;
    font-size: 24px;
    padding: 20px 0 0 0;
    strong {
      color: $mengo;
      width: 100%;
      display: inherit;
    }
  }
  p {
    padding: 10px 0 10px;
    color: $gray;
    font-size: 14px;
  }
  button {
    border: none;
    background: $pink;
    color: $white;
    padding: 10px 25px;
    text-transform: uppercase;
    font-size: 14px;
    margin-top: 10px;
  }
}

.quickconnect {
  font-weight: bold;
  margin-bottom: 60px;
  margin-top: 30px;
  .quickconnectlink {
    .borderbox {
      border-right: 1px solid $gray;
    }
    h3 {
      font-size: 24px;
      font-weight: bold;
      padding: 0 0 5px;
    }
    span {
      color: $gray;
      width: 100%;
      display: inline-block;
    }
    a {
      color: $pink;
      text-decoration: none;
    }
  }
}
