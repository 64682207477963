@mixin checkboxcustom {
  label {
    color: var(--aqua-1);
    font-size: 14px;
    font-weight: 500;
  }

  .custom-control-input:checked~.custom-control-label {
    color: var(--aqua-1) !important;

    &::before {
      color: $white;
      border-color: var(--aqua-7) !important;
      background-color: var(--aqua-7) !important;
    }
  }

  .custom-control-input:focus~.custom-control-label {
    &::before {
      box-shadow: none;
    }
  }

  .custom-control-input:focus:not(:checked)~.custom-control-label {
    &::before {
      border-color: var(--coral-4);
    }
  }
}

@mixin policycheckbox {
  label {
    color: var(--aqua-1);
    font-size: 14px;
    font-weight: 500;
    padding: 2px 7px 0 20px;
  }

  i {
    color: $deyouk;
  }

  .custom-control-label::after,
  .custom-control-label::before {
    left: 0;
    border-color: var(--coral-4);
  }

  .custom-control-input:checked~.custom-control-label {
    color: var(--aqua-1);

    &::before {
      color: $white;
      border-color: var(--aqua-7);
      background-color: var(--aqua-7);
    }
  }

  .custom-control-input:focus~.custom-control-label {
    &::before {
      box-shadow: none;
    }
  }

  .custom-control-input:focus:not(:checked)~.custom-control-label {
    &::before {
      border-color: var(--coral-4);
    }
  }
}
.custom-control-input:disabled~.custom-control-label::before, .custom-control-input[disabled]~.custom-control-label::before{
  
  background-color: var(--gray-1) !important;
  border: 1px solid var(--gray-2) !important;
}
