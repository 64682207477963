.madeourbanner {
  // background: $gray;
  padding: 7rem 0 4rem 0;
  color: $lightblack;
  // background: url("../../../Images/headbanner@2x.png") no-repeat 56% 0%;
  // background-size: cover;

  span {
    font-size: 36px;
    font-weight: bold;
    color: var(--aqua-9);
  }

  p {
    font-size: 26px;
    border-bottom: 2px solid var(--aqua-1);
    margin-bottom: 0;
    color: var(--aqua-1);
    width: 320px;
  }
}
.madedetailstabel1 , .madedetailstabel
{
table, th, td {
  border: 1px solid var(--coral-4) !important;
  border-collapse: collapse !important;
  vertical-align: bottom !important;
  // padding: 5px;
  // padding-right: 366px;
  // border-top-left-radius: 30px;
  border-radius: 20px !important;
  color: var(--aqua-9);
}
}
.madedetailstabel2
{
table, th, td {
  // border: 1px solid var(--coral-4) !important;
  // border-collapse: collapse !important;
  // vertical-align: bottom !important;
  // padding: 5px;
  // padding-right: 366px;
  // border-top-left-radius: 30px;
  // border-radius: 20px !important;
}
}
.madeourmain {
  // background: $ghostwhite;
  padding: 3rem 0;

  .madeoudayrtitle {
    &::before {
      margin-bottom: 15px;
      border-bottom: 1px solid var(--gray-2);
      width: 40%;
      position: absolute;
      top: 100px;
      content: "";
    }

    span {
      color: var(--aqua-9);
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 15px;
      display: inline-block;
    }

    p {
      color: var(--aqua-1);
      font-size: 14px;
      margin-bottom: 0;
    }
  }

  .madeourtable {
    margin-top: 40px;
  }

  .madequrdayimg {
    position: absolute;
    border-radius: 25px;
    top: -70px;
    right: -5px;
    width: 80%;
    margin-top: 50px;
  }

  .madebottomlink {
    color: $lightblack;
    padding-top: 30px;
    border-bottom: 1px solid var(--gray-2);
    padding-bottom: 10px;

    span {
      text-decoration: none;
      color: var(--aqua-9);
    }
  }

  .madedetails {
    strong {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 30px;
      display: inline-block;
      color: var(--aqua-9);
    }

    p {
      font-size: 14px;
      color: var(--aqua-1);

      b {
        // padding-left: 10px;
      }
    }
  }

  .madedetailstabel1 {
    box-shadow: 0px 3px 14px var(--aqua-1);
    border-radius: 10px;
    margin-bottom: 50px;
    width: 815px;
    margin-top: 5px;
    padding: 10px;
    table{
      width: 100%;
      tr{
        th{
          color: var(--aqua-9);
          
        }
        
        td{
          color: var(--aqua-1);
          padding: 10px;
        }
      }
    }
  }

  .madedetailstabel2 {
    box-shadow: 0px 3px 14px var(--aqua-1);
    border-radius: 10px;
    margin-bottom: 50px;
    width: 815px;
    padding: 10px;
    > img {
      // background-image: url(../../../Images/mainlogo.svg);
      background-repeat: no-repeat;
      position: absolute;
      margin: 0 auto;
      text-align: center;
      left: 0;
      right: 0;
      top: 30%;
      z-index: 1;
    }
    table{
      tr{
        th{
          color: var(--aqua-9);
          padding-right: 329px;
        }
        
        td{
          color: var(--aqua-1);
          padding: 10px;
          padding-right: 281px;
        }
      }
    }
  }
  .madedetailstabelwithdesiease {
    padding-top: 10px;

    > img {
      // background-image: url(../../../Images/mainlogo.svg);
      background-repeat: no-repeat;
      position: absolute;
      margin: 0 auto;
      text-align: center;
      left: 0;
      right: 0;
      top: 30%;
      z-index: 1;
    }
  }
  .madedesdetials {
    padding: 30px 0 0px;

    p {
      font-size: 14px;
    }
  }

  .exclusioncla {
  }

  .paymentnote {
    font-size: 14px;
    padding: 15px 0;

    p {
      color: var(--aqua-1);

      &:nth-child(2) {
        color: $pink;
      }
    }

    button {
      background-color: var(--aqua-9);
      color: $white;
      padding: 8px 15px;
      border-radius: 50px;
      margin-top: 20px;
      display: inline-block;
      text-decoration: none;
      outline: none;
    }
  }

  .paymentsic {
    font-size: 14px;
    padding: 20px 0 0;

    p {
      color: var(--aqua-1);
      margin-bottom: 0;
    }
  }
  table {
    tr{
      border: 1px solid var(--coral-4);
      text-align: center;  
      td {
        color: var(--aqua-1);
        border: 1px solid var(--coral-4);
        text-align: center;
  
      }
      th {
        color: var(--aqua-9);
        text-align: center;
  
      }
    }
  }
  
}

// Table

.ui-table {
  table {
    font-size: 0.9375em;
    border-collapse: collapse;
    border-spacing: 0;
    max-width: 100%;
    width: 100%;
    empty-cells: show;
    margin-bottom: 20px;
  }

  table caption {
    text-align: left;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    color: rgba(17, 17, 19, 0.4);
    letter-spacing: 1px;
    margin-bottom: 2px;
  }

  th {
    font-weight: 700;
    color: inherit;
    text-align: left;
    vertical-align: bottom;
  }

  td {
    color: inherit;
    vertical-align: top;
  }

  tbody.is-middle td,
  tr.is-middle td,
  td.is-middle {
    vertical-align: middle;
  }

  th,
  td {
    padding: 16px 16px;
    border-bottom: 1px solid #ec1968;
  }
  th::before,
  th::after,
  td::before,
  td::after {
    content: "";
    display: block;
    height: 0;
    width: 0;
  }
  th::before,
  td::before {
    margin-top: calc((0.75 - 1.5) * 0.49em);
  }
  th::after,
  td::after {
    margin-bottom: calc((0.75 - 1.5) * 0.49em);
  }
  th:first-child,
  td:first-child {
    padding-left: 0;
  }
  th:last-child,
  td:last-child {
    padding-right: 0;
  }

  tbody th,
  tbody td {
    border-width: 1px;
    border-color: #ec1968;
  }

  tfoot th,
  tfoot td {
    font-weight: bold;
  }

  table.is-border td,
  table.is-border th,
  table.is-bordered td,
  table.is-bordered th {
    border: 1px solid #ec1968;
  }

  table.is-border tbody th,
  table.is-border tbody td,
  table.is-bordered tbody th,
  table.is-bordered tbody td {
    border-bottom-width: 1px;
    border-bottom-color: #ec1968;
  }

  table.is-striped tr:nth-child(odd) td {
    background-color: #fafafa;
  }

  table.is-border td:first-child,
  table.is-border th:first-child,
  table.is-bordered td:first-child,
  table.is-bordered th:first-child,
  table.is-striped td:first-child,
  table.is-striped th:first-child {
    padding-left: 16px;
  }

  table.is-border td:last-child,
  table.is-border th:last-child,
  table.is-bordered td:last-child,
  table.is-bordered th:last-child,
  table.is-striped td:last-child,
  table.is-striped th:last-child {
    padding-right: 16px;
  }

  @media only screen and (max-width: 767px) {
    table.is-responsive th {
      display: none;
    }
    table.is-responsive tr,
    table.is-responsive td {
      display: block;
    }
    table.is-responsive tr:nth-child(odd) {
      background-color: #fafafa;
    }
    table.is-responsive td {
      padding: 16px;
      text-align: right;
    }
    table.is-responsive td:before {
      margin-top: 2px;
      content: attr(data-label);
      float: left;
      font-size: 0.875em;
      font-weight: bold;
      text-transform: uppercase;
      white-space: nowrap;
    }
  }

  .is-table-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 16px;
  }
  .is-table-container table {
    margin-bottom: 0;
  }
}

