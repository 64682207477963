.custome-select {
  position: relative;
  label + .MuiInput-formControl {
    background: transparent;
    padding-left: 10px;
  }
  select {
    box-sizing: border-box;
    width: 100%;
    &:focus {
      outline: none;
    }
  }

  .select {
    border: 0;
    padding: 4px 0;
    border-bottom: 1px solid $cerebralgrey;
    background-color: transparent;
  }

  .select ~ label {
    position: absolute;
    font-size: 12px;
    color: $cerebralgrey;
    left: 0;
    top: 10px;
    transition: 0.3s;
    // z-index: -1;
  }

  .select:focus ~ label,
  .select:not([value=""]):valid ~ label {
    top: -16px;
    transition: 0.3s;
  }
}

.custome-select {
  label {
    z-index: 1;
    color: var(--aqua-9);
    margin-top: 0px;
    margin-left: 7px;
  }
  top: px;
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none;
    border-bottom: 1px solid $lightblack;
  }
  .MuiInput-underline:after {
    // border-bottom: 1px solid $lightblack;
  }
  .MuiSelect-select:focus {
    background-color: transparent;
    // border-radius: 50px;
    // border: 1px solid var(--aqua-9) ;
    
  }
  .MuiSelect-select.MuiSelect-select {
    font-size: 14px;
    color: var(--aqua-9);
  }
  > div {
    width: 100%;
    // background-color: var(--aqua-2);
    svg {
      right: 0px;
    }
  }
  .MuiSelect-select.MuiSelect-select.Mui-disabled {
    color: var(--gray-3);
  }
}
// .MuiOutlinedInput-root {
//   border-radius: 50px !important;
// }

// .MuiFormControl-root{
//   border-radius: 50px !important;

// }
// .MuiFormLabel-root.Mui-focused{
//   margin-top: -15px !important;
// }



