.paymentreceivedbox {
  @include boxshow;
  margin-top: 70px;
  text-align: center;
  box-shadow: 0px 3px 14px var(--aqua-1) !important;
  border-radius:25px !important;
  img{
    width: 20%;
    height: 20%;
  }
  span {
    width: 100%;
    display: inline-block;
    font-size: 24px;
    padding: 20px 0 0 0;
    color: var(--aqua-9);
    strong {
      color: var(--aqua-9);
      width: 100%;
      display: inherit;
    }
  }
  p {
    padding: 10px 0 10px;
    color: var(--aqua-1);
    font-size: 14px;
  }
  button {
    border: 1px solid var(--aqua-9);
    background: var(--aqua-9);
    border-radius: 50px !important;
    color: $white;
    padding: 10px 25px;
    text-transform: none;
    font-size: 14px;
    margin-top: 10px;
    &:hover{
      filter: brightness(80%);
    }
    &:focus{
      outline: none;
    }
  }
  .paymentSuccessImg{
    position: absolute;
    top: 70px;
    right: 15px;
  }
  .paymentImg{
    min-width:100% ;
    border-radius: 25px;
  }
}

.quickconnect {
  font-weight: bold;
  margin-bottom: 60px;
  margin-top: 30px;
  .quickconnectlink {
    .borderbox {
      border-right: 1px solid $gray;
    }
    h3 {
      font-size: 24px;
      font-weight: bold;
      padding: 0 0 5px;
    }
    span {
      color: var(--aqua-9);
      width: 100%;
      display: inline-block;
    }
    a {
      color: var(--aqua-1);
      text-decoration: none;
    }
  }
}

.paymentpagefooter {
  @include fixedfooter;
  position: fixed;
  padding: 10px;
  button,
  .button:focus {
    color: $white;
    background: $pink;
    border-radius: 5px;
    font-size: 14px;
    padding: 12px 15px;
    border: 1px solid $pink;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    font-weight: bold;
    outline: none;
    text-transform: uppercase;
    font-size: 14px;

    &:hover {
      background: $darkpink;
      color: $white;
    }
  }
  .footerbtn {
    float: right;
    span {
      font-weight: bold;
    }
  }

  .skipbtn {
    button {
      border: 1px solid $pink;
      background: transparent;
      color: $pink;
      font-size: 14px;
      &:hover {
        background: $pink;
        color: $white;
      }
    }
  }
}

.paymentaddbutton {
  button,
  .button:focus {
    color: $white;
    background: $pink;
    border-radius: 5px;
    font-size: 14px;
    padding: 12px 15px;
    border: 1px solid $pink;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    font-weight: bold;
    outline: none;
    text-transform: uppercase;
    font-size: 14px;

    &:hover {
      background: $darkpink;
      color: $white;
    }
  }
}
