$white: #ffffff;
$lightwhite: #fcfcfc;
$deuwhite: #f5faff;
$ghostwhite: #f7f9ff;
$black: #000000;
$carbon: #333333;
$deyouk: #c9c9c9;
$spinelblack: #43425d;
$lightblack: #2d363a;
$slategray: #898d8f;
$blue: #034ea2;
$lightblue: #0c4da2;
$aliceblue: #f1f8ff;
$seblack: #142132;
// $deadsea: #cff4f9;
$deadsea: #cfe6ff;
$sky: #bfddff;
$skies: #cddcec;
$cerebralgrey: #cccccc;
$dhusargrey: #aaaaaa;
$lightgray: #f8f8f8;
$greyhar: #969a9c;
$gray: #989898;
$dugong: #707070;
$grass: #4caf50;
$lixagreen: #00e640;
$darkgreen: #00bf78;
$terminalgreen: #68d100;
$pink: var(--aqua-9);
$darkpink: var(--aqua-9);
$clouded: #d1d1d1;
$mengo: #fba927;
$blush: #fff2f7;
$mengogradient: linear-gradient(104deg, #ffae00 0%, #ffc13d 100%) 0% 0%;
$goldplan: transparent linear-gradient(61deg, #feae03 0%, #ffc956 100%) 0% 0%;
$sliverplan: transparent linear-gradient(180deg, #666666 0%, #757575 100%) 0% 0%;
$goldlight: transparent linear-gradient(180deg, #ffb20b 0%, #f8cb6d 100%) 0% 0%;
$tootipgray: #d4d4d4