.comparisonpriceplan {
  @include boxshow;
  margin-bottom: 170px;
  margin-top: 110px;
  box-shadow: 0px 3px 14px var(--aqua-1);
  border-radius:25px;

  td:nth-child(2),td:nth-child(3){
    text-align: center;
    background: white !important;
  }

  img{
    width: 25px;
    height: 25px;
  }

  td:first-child{
    border-top: 1px solid var(--gray-2) !important;
  }

  .pricetabel tabel tr td:first-child i {
    padding-left: 10px;
    background-color: $white;
    color: $deyouk;
  }
}
.comparisongoldpalntop {
  margin-top: 110px;
  padding: 0px 20px;
  border-radius: 25px;
  box-shadow: 0px 3px 14px var(--aqua-1);
}
.comparisonpricetabel {
  tabel {
    width: 100%;
    display: inline-table;
    tr {
      margin: 0 15px;
      th {
        &:nth-child(3) {
          background: $sliverplan !important;
          left: 15px;
          position: relative;
        }
        &:nth-child(2) {
          background: $mengogradient !important;
        }
        &:nth-child(2),
        &:nth-child(3) {
          color: $white;
          padding: 15px 20px;
          text-align: left;
          border-radius: 8px;
          p {
            margin: 0;
            padding: 0;
            font-size: 14px;
            font-weight: normal;
          }
          span {
            font-size: 24px;
            font-weight: bold;
          }
        }
      }
    }
    tr {
      td {
        position: relative;
        &:first-child {
          color: $lightblack;
          padding: 10px 0;
          font-size: 14px;
          border-top: 1px solid rgba($lightblack, 0.1);
          i {
            color: $deyouk;
            padding-left: 10px;
          }
        }
        &:nth-child(2) {
          background: $lightgray;
          padding-top: 15px;
        }
        &:nth-child(3) {
          left: 15px;
          padding: 10px 0;
        }
        &:nth-child(2),
        &:nth-child(3) {
          i {
            color: $grass;
            font-size: 20px;
            margin: 0 auto;
            text-align: center;
            width: 100%;
            min-width: 200px;
          }
        }
      }
    }
  }
}

.comparisontabel {
  width: 100%;
  display: inline-table;
  tr {
    margin: 0 15px;
  }

  tr {
    &:nth-child(1) {
      td {
        font-size: 18px !important;
        padding: 40px 0 !important;
        i {
          color: $terminalgreen !important;
          padding: 10px !important;
        }
      }
    }
    td {
      position: relative;
      &:first-child {
        color: $lightblack;
        padding: 10px 0;
        font-size: 14px;
        border-top: 1px solid var(--gray-2);
        i {
          color: $deyouk;
          padding-left: 10px;
        }
      }
    }
  }
  img{
    width: 25px;
    height: 25px;
    margin-right: 4px;
  }
}

.comparisonplan {
  background: var(--mustard-1);
  text-align: center;
  padding: 40px 0;
  min-height: 550px;
  position: relative;
  border-radius: 25px;
  h3 {
    color: $white;
    font-weight: bold;
  }
  .comparisonplanprice {
    color: $white;
    position: absolute;
    bottom: 20px;
    width: 100%;
  }
  span {
    font-size: 20px;
    font-weight: bold;
  }
  p {
    font-size: 14px;
  }
  button {
    background: var(--aqua-9);
    color: $white;
    border-radius: 50px;
    border: 1px solid var(--aqua-9);
    padding: 10px 18px;
    &:hover{
      filter: brightness(80%);
    }
    &:focus {
      outline: none;
    }
  }
}

.planexclusions {
  h3 {
    color: $lightblack;
    font-weight: bold;
    font-size: 18px;
    padding: 43px 10px 43px;
    i {
      color: var(--coral-4);
      padding: 0 15px 0 0;
    }
  }
}

.planexclusionslist {
  tabel {
    tr {
      td {
        color: $lightblack !important;
        padding: 10px 0 !important;
        font-size: 14px !important;
        border-top: 1px solid var(--gray-2) !important;
      }
    }
  }
  .pricetabel tabel tr td:first-child i {
    padding-left: 10px;
    background-color: $white;
    color: $deyouk;
  }

  .pricetabel tabel tr td:first-child{
    min-width: 0px;
  }
}

.plancomparisonfooter {
  padding: 20px;
  background: $white;
  box-shadow: 0px 3px 14px var(--aqua-1);
  margin: 0 auto;
  width: 100%;
  text-align: left;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 1;
  tabel {
    width: 100%;
    display: inline-table;
    tr {
      margin: 0 15px;
      th {
        button {
          color: $white;
          background: transparent;
          border: none;
        }
        padding-left: 30px;
        font-size: 20px;
        b {
          text-transform: uppercase;
          font-size: 14px;
          float: left;
          font-weight: normal;
          padding: 18px 10px 0 0px;
        }
        .footerprice {
          display: inherit;
          i {
            position: absolute;
            right: 10px;
            top: 20px;
          }
          span {
            font-size: 20px;
          }
          p {
            margin: 0;
            font-size: 10px;
            font-weight: normal;
          }
        }
        &:nth-child(3) {
          background: var(--gray-2) !important;
          left: 35px;
          position: relative;
        }
        &:nth-child(2) {
          background: var(--mustard-1) !important;
          right: -20px;
          position: relative;
        }
        &:nth-child(2),
        &:nth-child(3) {
          color: $white;
          text-align: left;
          border-radius: 50px;
          width: 245px;
          padding: 10px 10px;
        }
      }
    }
  }
}

.pricetabel tabel tr th:nth-child(2),.pricetabel tabel tr th:nth-child(3){
  background-image: none !important;
}
