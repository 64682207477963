.chooseplanmain {
  box-shadow: 0px 3px 14px var(--aqua-1);
  padding: 0px 0 10px;
  // margin-top: 110px;
  .updatebutton {
    text-align: right;
    margin-top: 10px;
    button {
      background: $clouded;
      color: $white;
      padding: 10px 15px;
      border-radius: 5px;
      border: none;
      outline: none;
      font-size: 14px;
    }
  }

  .custome-select {
    select,
    select:focus {
      width: 100%;
      border: none;
      border-bottom: 1px solid $lightblack;
      padding: 4px 0;
      color: $seblack;
      font-size: 14px;
      background-image: linear-gradient(45deg, transparent 50%, $seblack 60%),
        linear-gradient(135deg, $seblack 40%, transparent 50%) !important;
      background-position: calc(100% - 6px) 14px, calc(100% - 0px) 14px, 100% 0;
      background-size: 6px 6px, 6px 6px;
      background-repeat: no-repeat;
      -webkit-appearance: none;
      -moz-appearance: none;
    }

    label {
      color: $seblack;
      font-size: 12px;
      opacity: 0.4;
    }
  }
  .custome-input {
    input:hover, .MuiSelect-select:hover{
      color: var(--aqua-1);
    }
    &:hover {
      label {
        color: $lightblack;
      }
      .MuiInputBase-root.Mui-disabled {
        color: $lightblack;
      }
    }
  }
}
.inclustionsmain {
  margin-bottom: 150px !important;
}
.PlanDetailsbox {
  padding: 30px;
  background: $white;
  box-shadow: 0px 3px 14px var(--aqua-1);
  border-radius: 25px;
  margin: 20px 0;
  .plansubtitle {
    p {
      opacity: 0.4;
      color: $lightblack !important;
    }
  }
  .plantitle {
    span {
      color: $lightblack;
      font-size: 20px;
      font-weight: bold;
      width: 100%;
      display: inline-block;
      line-height: 22px;
    }
    p {
      color: $mengo;
      font-size: 14px;
      padding-top: 10px;
      padding-bottom: px;
      margin: 0;
      font-weight: 400;
    }
    b {
      margin-bottom: 20px;
      display: inline-block;
      margin-top: 10px;
    }
  }
  .planprice {
    text-align: right;
    span {
      color: $lightblack;
      font-size: 22px;
      font-weight: bold;
    }
    p {
      color: $lightblack;
      font-size: 14px;
      font-weight: bold;
    }
  }

  .choseplanmain {
    border-top: 1px solid var(--gray-2);
    padding: 30px 0 0;
  }

  .inclustionsheader {
    text-align: left;
    border-bottom: 1px solid var(--gray-2);
    span {
      font-size: 20px;
      font-weight: 600;
      color: $black;
    }
    p {
      font-size: 14px;
      color: $gray;
    }
  }
  .inclustionsList {
    border-bottom: 1px solid var(--gray-2);
    padding: 20px 0;
    span {
      font-size: 16px;
      font-weight: 600;
      color: $lightblack;
      margin-bottom: 5px;
      display: inline-block;
    }
    p {
      font-size: 14px;
      color: $lightblack;
      margin-bottom: 0;
      font-weight: 400;
      opacity: 0.5;
    }
  }
}

.floterplan {
  min-height: 63px;
  // background: url("../../../Images/floterplanBg.svg");
  background-size: cover;
  border-radius: 10px;
  color: $white;
  background-color: var(--mustard-1);
  // padding: 20px;
  span {
    font-size: 18px;
    padding: 0 0 5px;
    display: inherit;
    padding-left: 10px;
    padding-top: 5px;
  }
  p {
    font-size: 12px;
    margin-bottom: 0;
    padding-left: 10px;
    cursor: pointer;
    i {
      padding-left: 10px;
      font-size: 14px;
      font-weight: bold;
      vertical-align: bottom;
    }
  }
  strong {
    font-size: 24px;
  }
  /* ------------------- */
  /* LOADER */
  /* ------------------- */
  .loading-container {
    text-align: center;
  }

  .circle {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #ec1968;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }

  /* ----- LEFT CIRCLE ----- */
  .left-circle {
    animation-name: left-circle;
    animation-timing-function: ease-in;
  }

  @keyframes left-circle {
    0% {
      top: 0;
    }
    50% {
      top: 50px;
    }
    60% {
      transform: scale(1.15);
      background-color: #ec1968;
      opacity: 0.1;
    }
    100% {
      top: 0px;
    }
  }

  /* ----- MIDDLE CIRCLE ----- */
  .middle-circle {
    animation-name: middle-circle;
    animation-timing-function: linear;
  }

  @keyframes middle-circle {
    0% {
      top: 0;
    }
    50% {
      top: 50px;
      transform: scale(1.2);
      opacity: 0.1;
    }
    70% {
      background-color: #ec1968;
    }
    100% {
      top: 0;
    }
  }

  /* ----- RIGHT CIRCLE ----- */
  .right-circle {
    animation-name: right-circle;
    animation-timing-function: ease-out;
  }

  @keyframes right-circle {
    0% {
      top: 0;
    }
    50% {
      top: 50px;
      transform: scale(1.2);
      background-color: #ec1968;
      opacity: 0.1;
    }
    100% {
      top: 0;
    }
  }
}

.plandetails {
  margin: 20px 0 0 0;
  color: $lightblack;
  i {
    background: $pink;
    color: $white;
    width: 15px;
    height: 15px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    font-size: 11px;
    margin: 0 7px 0 0;
  }
  span {
    // font-size: 16px;
    // color: $black;
    // font-weight: bold;
    font-size: 12px;
    padding-left: 0;
    padding-top: 10px;
  }
}

.modalcomman {
  width: 100% !important;
  padding: 0 20px;
  .modal-content {
    display: inline-block;
    border: none;
    .modal-header {
      border-bottom: none;
      padding: 0;
    }
    .modaltitle {
      text-align: left;
      padding-bottom: 20px;
      span {
        font-weight: bold;
        font-size: 20px;
        padding-bottom: 10px;
        display: inline-block;
      }
      p {
        font-size: 16px;
        margin: 0;
        color: var(--aqua-9);
      }
      button {
        text-align: right;
        position: absolute;
        right: 0px;
        top: -16px;
        border: none;
        background: none;
        font-size: 22px;
        outline: none;
        color: var(--coral-4);
      }
    }

    .modal-body {
      // width: 70% !important;
      // margin: 0 auto;
      padding: 1rem 0;
      .modalfrm {
        .custome-input {
          label {
            padding-left: 0px;
          }
          label + .MuiInput-formControl {
            margin-top: 16px;
          }
          .MuiInput-formControl {
            border-bottom: 1px solid $deyouk;
            padding: 0px 0px 0px;
            input {
              padding-left: 0px;
              color: var(--aqua-9);
            }
          }
          .MuiInput-underline {
            &:before {
              border-bottom: none;
            }
          }
        }
        .modalbtn {
          width: 100%;
          margin: 40px auto 0;
          text-align: center;
          button {
            border: 1px solid var(--aqua-9);
            border-radius: 50px;
            background: var(--aqua-9);
            color: $white;
            margin: 0 5px;
            padding: 10px 20px;
            outline: none;
            min-width: 130px;
            font-weight: bold;
            text-transform: none;
            font-size: 14px;
          }
          
          .yesbtn {
            color: var(--aqua-9);
            border: 1px solid var(--aqua-9);
            border-radius: 50px;
            background: $white;
            &:hover {
              background: var(--aqua-9);
              color: $white;
            }
          }
        }
      }
    }
  }
}

// Cost Breakdown Popup
.costpopup {
  margin: 0 !important;
  .modal-content {
    border: none;
    tabel {
      width: 100%;
      display: inline-block;
      thead {
        border-bottom: 1px solid $deyouk;
        padding-bottom: 8px;
        margin-bottom: 15px;
        display: inline-block;
        width: 100%;
        tr {
          th {
            &:first-child {
              font-size: 22px;
            }
            button {
              text-align: right;
              position: absolute;
              right: 7px;
              top: -8px;
              border: none;
              background: transparent;
              font-weight: bold;
              font-size: 22px;
              outline: none;
              color: var(--coral-4);
            }
          }
        }
      }
      tbody {
        width: 100%;
        display: inline-block;
        tr {
          width: 100%;
          display: inline-block;
          td {
            width: 50%;
            padding: 5px 0;
            float: left;
            &:last-child {
              width: 50%;
              text-align: right;
            }
          }
        }
      }
      tfoot {
        border-top: 1px solid $deyouk;
        padding-top: 8px;
        margin-top: 15px;
        display: inline-block;
        width: 100%;
        tr {
          width: 100%;
          display: inline-block;
          td {
            padding: 5px 0;
            float: left;
            width: 50%;
            &:last-child {
              text-align: right;
              font-size: 24px;
              font-weight: 500;
            }
            span {
              display: inherit;
              font-size: 12px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}

[role="presentation"] {
  z-index: 9999 !important;
}
.dot {
  height: 30px;
  width: 150px;
  color: rgba(0, 230, 64, 1);
  background-color: transparent !important;
  i {
    width: 10px !important;
    margin: 1px 5px 0 5px !important;
    height: 20px !important;
  }
  span {
    margin: 0 5px !important;
  }
}

.editpedpopup {
  .modal {
    overflow: unset !important;
  }
}

.closebtn {
  border: none;
  background: transparent;
  font-weight: bold;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 20px;
  outline: none;
  &:focus {
    outline: none;
  }
}
