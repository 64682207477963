@mixin dateteinput {
  > div {
    margin-top: -3px;
    margin-bottom: 0px;
    // border-radius: 50px;
    // border: 1px solid var(--aqua-9);
  }
  button {
    padding: 0;
  }
  .MuiInputBase-input {
    height: auto;
    font-size: 14px;
    padding-right: 20px;
    // background-color: var(--aqua-2);
    border-radius: 50px;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none;
    border-bottom: 1px solid $lightblack;
  }
  .MuiInput-underline:after {
    // border-bottom: 1px solid $lightblack;
  }
}

.custome-input-date {
  position: relative;
  @include dateteinput;
  label + .MuiInput-formControl {
    // background: transparent;
    // padding-left: 10px;
  }
  label {
    z-index: 1;
    // padding-left: 10px;
  }
  input {
    box-sizing: border-box;
    &:focus {
      outline: none;
    }
  }

  .input {
    border: 0;
    padding: 4px 0;
    border-bottom: 1px solid $cerebralgrey;
    background-color: transparent;
  }

  .input ~ label {
    position: absolute;
    left: 0;
    top: 10px;
    transition: 0.3s;
    // z-index: -1;
  }

  .input:focus ~ label,
  .input:not(:placeholder-shown) ~ label {
    top: -16px;
    transition: 0.3s;
  }
}
.MuiInputAdornment-positionEnd{
  margin-left: 0 !important;
}
