
.headermain {
  background-color: $white;
  padding: 20px 0;
  //position: fixed;
  width: 100%;
  z-index: 999;
  position: relative;
  box-shadow: 0px 3px 14px  rgb(36, 180, 179);

  img {
    height: 50px;
    // margin-top: 4px;
    width: 120px;
  }

  .tolfreenumber {
    color: rgb(0, 70, 79);
    margin-right: 0px;
    i {
      font-size: 30px;
      left: 0px;
      top: 8px;
      position: absolute;
      padding-left: 40px;
    }

    p {
      padding-top: 0px;
      padding-right: 0px !important;
      padding-bottom: 0px;
      padding-left: 60px;
      margin: 0;
      font-size: 13px;
    }

    a {
      font-weight: bold;
      padding-top: 0px;
      padding-right: 0px !important;
      padding-bottom: 0px;
      padding-left: 60px;
      color: rgb(0, 70, 79);
      text-decoration: none;
      font-size: 16px;
    }
    .vert {
      padding-right: 0px !important;
      border-left: 1.5px solid rgb(0, 70, 79);
      height: 40px;
    }

    #phone2 {
      // padding-left: 20px;
      margin-left: 0px !important;
    }
  }


  .tolfreenumber2 {
    color: rgb(0, 70, 79);
    margin-right: 0px;
    i {
      font-size: 30px;
      left: 0px;
      top: 8px;
      position: absolute;
      padding-left: 5px;
    }

    p {
      padding-top: 0px;
      padding-right: 0px !important;
      padding-bottom: 0px;
      padding-left: 5px;
      margin: 0;
      font-size: 13px;
    }

    a {
      font-weight: bold;
      padding-top: 0px;
      padding-right: 0px !important;
      padding-bottom: 0px;
      padding-left: 5px;
      color: rgb(0, 70, 79);
      text-decoration: none;
      font-size: 16px;
    }
    .vert {
      padding-right: 0px !important;
      border-left: 1.5px solid rgb(0, 70, 79);
      height: 40px;
    }

    #phone2 {
      // padding-left: 20px;
      margin-left: 0px !important;
    }
  }

  .menuicon {
    color: rgb(0, 70, 79);
    font-size: 22px;
    vertical-align: middle;
    padding-top: 10px;
    margin-left: -40px;
  }

  .simple-menu {
    z-index: 9999;
  }
}

.header-top-spacing {
  margin-top: 10px !important;

  // input {
  //   font-size: 14px;
  // }

  .MuiInput-underline.Mui-disabled:before {
    border-bottom-style: solid;
  }
}

.MuiFormControl-root {
  width: 100% !important;
}

.hang-hover {
  cursor: pointer;
}

.vertical {
  border-left: 3px solid white;
  height: 40px;
}

.contactUsDrawer .MuiDrawer-paperAnchorTop {
  border-radius: 50px;

  .tollfreenumber, .landline {
    p {
      color: var(--aqua-9);
    }
    a {
      color: var(--aqua-9);
      font-weight: bold;
    }
  }
}

.contactUs-popup {
  display: flex;
  justify-content: center;
  font-size: larger;
  align-items: center;
  padding: 15px 0px;
  i {
    color: var(--aqua-9);
  }
}