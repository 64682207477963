.selecteplantopbox {
  padding: 20px;
  background: $white;
  box-shadow: 0px 3px 14px var(--aqua-1);
  border-radius: 25px;
  margin-top: 30px;
  margin-bottom: 20px;

  .selecteplandtitle {
    span {
      font-size: 24px;
      color: var(--mustard-1);
      font-weight: bold;
    }

    p {
      font-size: 12px;
      color: var(--aqua-1);
      margin-bottom: 0;
    }
  }

  .pricelist {
    text-align: right;

    i {
      vertical-align: text-top;
      color: $cerebralgrey;
    }

    span {
      font-size: 24px;
      color: var(--aqua-9);
      font-weight: bold;
    }

    p {
      font-size: 12px;
      color: var(--aqua-1);
      margin-bottom: 0;
    }
  }
}

.nomineedetails {
  margin-bottom: 8px;
  .row .mt-5{
    margin-top: 26px !important;
  }
  .radioBtn{
    border:2px solid white;
    box-shadow:0 0 0 1px var(--coral-4);
    appearance:none;
    border-radius:50%;
    width:12px;
    height:12px;
    background-color:#fff;
    transition:all ease-in 0.2s;
  
  }
  .radioBtn:checked{
    background-color:var(--aqua-1);
    box-shadow:0 0 0 1px var(--aqua-1);
  }
}

.selfdetails {
  padding: 20px;
  background: $white;
  box-shadow: 0px 3px 14px var(--aqua-1);
  border-radius: 25px;
  margin-top: 20px;
  margin-bottom: 20px;

  .selfsubtitle {
    span {
      color: var(--aqua-9);
      font-size: 20px;
      font-weight: bold;
    }

    border-bottom: 1px solid var(--gray-2);
    padding: 0 0 10px;
  }

  .userdetails {
    padding: 20px 0 0;

    span {
      font-size: 1rem;
      font-weight: bold;
      color: var(--aqua-9);
    }

    p {
      font-size: 1rem;
      color: var(--aqua-1);
      padding: 5px 0;
      margin: 0;
    }
  }

  .custome-input{
    padding-top: 15px;
    p{
      b{
        color:var(--aqua-9);
      }
      p{
        color:var(--aqua-1);
      }
    }

  }
  .custome-input-date{
    padding: 18px 0 0;
  }
  .custome-select{
    padding: 15px 0 0;
  }
  .custome-input-date,
  .custome-select {
    // padding: 15px 0 0;
    // margin-top: 20px;
    .MuiInput-root{
      background-color: var(--aqua-2) !important;
    }

    label {
      font-size: 1rem;
      z-index: 9;
      // padding-left: 10px;
    }

    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    }

    label + .MuiInput-formControl {
      background: transparent;
    }
  }

  .invalid-feedback{
    font-size: 11px;
    color: black !important;
  }

  .custome-select .MuiSelect-selectMenu{
    background: transparent;
  }

  .customemail {
    width: 100%;
    word-break: break-word;
  }

  .selftitle {
    border-bottom: 1px solid var(--gray-2);
    color: $lightblack;
    margin-bottom: 10px;

    span {
      font-size: 18px;
      font-weight: bold;
      color: var(--aqua-9);
    }

    p {
      font-size: 14px;
      padding-bottom: 20px;
      color: $slategray;
    }
  }

  .paybox {
    label {
      margin: 5px 0 0 0;
    }

    .MuiInput-underline:before,
    .MuiInput-underline:hover {
      border-bottom: none !important;
    }

    input {
      border: 1px solid $deyouk;
      border-radius: 5px;
      padding: 10px 15px;
      font-weight: bold;
      height: auto;
    }
  }
  .MuiFormControl-root{
    label{
      padding-left: 10px;
    }
    .MuiInput-root{
      border: 1px solid #c9c9c9;
      border-radius: 50px;
      background-color: var(--aqua-2);
      &:before{
        border-bottom: 0px !important;
      }
      input{
        padding-left: 9px;
        padding-right: 6px;
        border-radius: 50px;
        color: var(--aqua-9)
      }
    }
    .MuiInput-root.Mui-focused{
      border: 1px solid var(--aqua-9);
    }
  }
}

.medicaltitle {
  margin: 40px 0 0 0;
  color: $blue;

  h3 {
    font-size: 16px;
    font-weight: bold;
    color: $black;
  }
}

.medicalchose {
  margin: 20px 0 0;
  @include checkboxcustom;
}

.nonineecheck {
  color: $blue;
  font-weight: bold;
  font-size: 14px;
  text-align: right;
}

.yesnobtn {
  text-align: right;

  .form-check-label {
    font-weight: bold;
  }
  
}

.seletedfooter {
  padding: 15px;
  // padding-left:250px ;
  background: $white;
  box-shadow: 0px 3px 14px var(--aqua-1);
  // border-radius: 10px;
  margin: 0 auto;
  width: 100%;
  // text-align: right;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 9;

  .footerbtn {
    float: right;
    span {
      font-weight: bold;
    }
  }

  .selectbtn {
    &::before {
      background: $blush;
      border-left: 1px solid $pink;
      position: absolute;
      content: "";
      width: 50px;
      height: 48px;
      right: 0;
      top: 0;
      border-radius: 0 5px 5px 0;
      z-index: 1;
    }

    &::after {
      right: 20px;
      position: absolute;
      z-index: 9;
      top: 19px;
    }

    &:hover {
      color: $pink;
    }
  }

  .dropdown-menu {
    margin-top: 0;
    margin-bottom: 1rem !important;
    width: 94%;
    border: 1px solid $pink;
    padding: 0;

    li {
      padding: 10px 30px;
      cursor: pointer;

      &:hover {
        background: transparent;
      }
    }
  }

  .dropdown-toggle {
    background: transparent;
    color: $pink;
    border: 1px solid;
    padding: 12px 70px 12px 25px;
    margin-right: 15px;
  }

  .NonProceedbtn {
    color: var(--aqua-9);
    border: 1px solid $pink;
    // padding: 11px 35px 15px;
    margin: 0px 20px;
    border-radius: 50px;
    background: $white;
    font-weight: bold;

    &:hover {
      background: var(--aqua-9);
      color: $white;
    }
  }

  .proceedbtn {
    border-radius: 50px;
    text-decoration: none;
    display: inline-block;
    border: 1px solid var(--aqua-9);
    background: var(--aqua-9);
    color: $white;
    text-align: center;
    font-weight: bold;

    &:hover {
      background: var(--aqua-9);
      color: $white;
    }
  }

  button {
    outline: none;
    box-shadow: none !important;
    padding: 8px 20px;
    min-width: 149px;
    text-align: center !important;
    &:focus {
      outline: none;
    }
  }

  .dropdown-menu {
    li {
      color: $pink;
      border-bottom: 1px solid;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .resendpaymentlink {
    text-align: left;
  }

  .proceedbtn {
    text-align: right;
  }

  .Customersuc {
    padding: 8px 0 0;
    display: inline-block;
    position: absolute;
    left: 10px;

    svg {
      margin-right: 5px;
    }
  }
}

.selectplanbtn {
  position: relative;

  button {
    background: $white;
    color: $pink;
    border-radius: 5px;
    border: 1px solid $pink;
    padding: 8px 15px;
    font-size: 14px;
    font-weight: bold;
    &:hover {
      background: $pink;
      color: $white;
    }
  }

  // .btn-group {
  //   position: absolute;
  //   left: 0;
  // }
  .planrightbtn {
    float: right;

    .takeconsentbtn {
      margin-left: 10px;
    }
    
    button {
      font-size: 14px;
      font-weight: bold;
      border-radius: 50px;
      &:first-child {
        margin-right: 10px;
      }

      &:last-child {
        color: $white;
        background: $pink;
        margin-left: 10px;
        border-radius: 50px;
        &:hover{
          filter: brightness(80%);
        }
      }
    }
  }
}

.dot {
  // position: relative;
  // left: 0;
  height: 30px;
  width: 150px;
  color: rgba(0, 230, 64, 1);
  background-color: transparent !important;

  i {
    width: 10px !important;
    margin: 1px 5px 0 5px !important;
    height: 20px !important;
  }

  span {
    margin: 0 5px !important;
    color: rgba(168, 168, 168, 0.7) !important;
  }
}

.paymentdetails {
  padding: 20px;
  background: $white;
  box-shadow: 0px 3px 14px var(--aqua-1);
  // border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;

  .selfsubtitle {
    span {
      color: $lightblack;
      font-size: 20px;
      font-weight: bold;
    }

    border-bottom: 1px solid rgba($blue, 0.2);
    padding: 0 0 10px;
  }

  .userdetails {
    padding: 20px 0 0;

    span {
      font-size: 1rem;
      font-weight: bold;
    }

    p {
      font-size: 1rem;
      color: rgba($lightblack, 0.8);
      padding: 5px 0;
      margin: 0;
    }
  }

  .custome-input,
  .custome-input-date,
  .custome-select {
    padding: 15px 0 0;
    

    label {
      font-size: 18px;
      z-index: 9;
      padding-left: 10px;
      pointer-events: none;
    }

    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    }

    label + .MuiInput-formControl {
      background: transparent;
    }
    //  label + .MuiInput-formControl {
    //   margin-top: 28px;
    //   border: 1px solid $deyouk;
    //   border-radius: 5px;
    // }
    .MuiSelect-root {
      padding-left: 10px;
    }
  }

  .customemail {
    width: 100%;
    word-break: break-word;
  }

  .selftitle {
    border-bottom: 1px solid $deadsea;
    color: $lightblack;
    margin-bottom: 10px;

    span {
      font-size: 18px;
      font-weight: bold;
    }

    p {
      font-size: 14px;
      padding-bottom: 20px;
      color: $slategray;
    }
  }

  .paybox {
    label {
      margin: 13px 0 0 0;
    }

    

    input {
      // border: 1px solid $deyouk;
      border-radius: 5px;
      padding: 10px 15px;
      font-weight: bold;
      height: auto;
    }
    label + .MuiInput-formControl {
      margin-top: 28px;
      border: 1px solid $deyouk;
      border-radius: 5px;
    }

    .MuiInput-underline {
      border: none;
    }

    .MuiInput-underline:before,
    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: none !important;
    }

    .custome-select .MuiSelect-select.MuiSelect-select {
      padding: 10px 15px;
    }
  }

  .crossbutton {
    border-style: none;
    background: none;
    color: red;
    font-weight: bold;
  }
}

.modal-dialog{
  .modaltitle .button{
    color: var(--coral-4);
  }
  .modalbtn {
    width: 100%;
    margin: 40px auto 0;
    text-align: center;
    button {
      border: 1px solid var(--aqua-9);
      border-radius: 50px;
      background: var(--aqua-9);
      color: $white;
      margin: 0 5px;
      padding: 10px 20px;
      outline: none;
      min-width: 130px;
      font-weight: bold;
      text-transform: none;
      font-size: 14px;
      &:hover{
        filter: brightness(80%);
      }
    }
  }
}

.sharequote-table-container {
  overflow-x: auto;
}

.sharequote-table-container table {
  width: max-content;
  white-space: nowrap;
}

.pageBackBtn {
  color: var(--aqua-9) !important;
  border: 1px solid var(--aqua-9) !important;
  background: $white !important;
  font-weight: bold;
  font-size: 14px;
  border-radius: 50px !important;
  text-transform: none !important;
  &:hover {
    background: var(--aqua-9) !important;
    color: $white !important;
    text-transform: none;
  }
  &:disabled{
    color: #666 !important;
    background-color: #ccc !important;
    border-color: #ccc !important;
  }
}

.copyLinkBtn {
  background-color: white;
  color: var(--aqua-9);
  margin-left: 10px;
  &:hover {
      background-color: var(--aqua-9);
      color: white;
  }
}