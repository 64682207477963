.customContainer{
    width: 100% !important;
    padding: 0;
    max-width: 100% !important;
    background-color: #edf4fc;
    padding-top: 10px;
}
// .makeStyles-root-1{
//     padding-left: 0px !important;
//     padding-right: 0px !important;
//     background-color: white !important;
//     .makeStyles-subHeader-2{
//         padding: 0px;
//         box-shadow: 0px 3px 14px var(--aqua-1);
//         margin-bottom: 35px ;
//         .makeStyles-policyNumContainer-7 p{
//             color: var(--aqua-9) !important;
//         }

//         .makeStyles-backButton-6{
//             color: var(--aqua-9) !important;
//         }

//         .MuiButton-root{
//             color: white !important;
//             background-color: var(--aqua-9) !important;
//             text-transform: none;
//             border-radius: 50px;
//             border: 1px solid var(--aqua-9) !important;
//             &:hover{
//                 filter: brightness(80%);
//             }
//         }
//     }

//     .MuiBox-root{
//         .MuiAccordionSummary-content{
//             svg{
//                 color: var(--coral-4) !important;
//             }
//             p{
//                 color: var(--aqua-9) !important;
//                 padding-top: 4px;
//             }
//         }

//         .MuiAccordion-rounded{
//             border-radius: 25px;
//             box-shadow: 0px 3px 14px var(--aqua-1) !important;
//             margin-top: 0px !important;

//             .MuiCollapse-container .MuiAccordionDetails-root{
//                 .MuiGrid-item{
//                     color: var(--aqua-9) !important;
//                     font-weight: bold;
//                     label{
//                         font-weight: normal;
//                         color: var(--aqua-1) !important;
//                     }
//                     .table-responsive{
//                         box-shadow: 0px 3px 14px var(--aqua-1);
//                         border-radius: 25px;
//                         .table{
//                             margin-bottom: 0px;
//                         }
//                         .table-bordered td, .table-bordered th{
//                             border: 1px solid var(--coral-4);
//                             color : var(--aqua-9) !important
//                         }
//                     }
//                     .table-bordered{
//                         .headings{
//                             color: var(--aqua-9);
//                         }
//                         td{
//                             color: black;
//                             font-weight: normal;
//                         }
//                         .MuiSvgIcon-colorSecondary
//                         {
//                             color: var(--aqua-9);
//                         }
//                     }
//                     .MuiFormControlLabel-root{
//                         .MuiFormControlLabel-label{
//                             color: var(--aqua-1) !important;;
//                         }
//                         .MuiCheckbox-colorPrimary.Mui-checked{
//                             color: var(--aqua-7) !important;
//                         }
//                         .MuiCheckbox-root{
//                             color: var(--coral-4) !important;;
//                         }
//                     }
//                 }
//             }
//         }
//         .MuiAccordion-root.Mui-expanded:last-child{
//             margin-bottom: 20px;
//         }
//         .makeStyles-checkBoxPaper-4{
//             box-shadow: 0px 3px 14px var(--aqua-1) !important;
//             border-radius: 25px !important;
//             .MuiGrid-item{
//                 color: var(--aqua-1);
//                 .MuiFormControlLabel-root{
//                     .MuiFormControlLabel-label{
//                         color: var(--aqua-1) !important;;
//                     }
//                     .MuiCheckbox-colorPrimary.Mui-checked{
//                         color: var(--aqua-7) !important;
//                     }
//                     .MuiCheckbox-root{
//                         color: var(--coral-4) !important;;
//                     }
//                 }
//                 img{
//                     max-width: 70%;
//                 }
//             }
//         }
//         .makeStyles-footer-3{
//             box-shadow: 0px 3px 14px var(--aqua-1);
//             width: 100%;
//             .MuiButton-contained.Mui-disabled{
//                 border: none !important;
//             }
//         }
//     }

//     .MuiButton-root{
//         color: var(--aqua-9);
//         background-color: transparent;
//         text-transform: none;
//         border-radius: 50px;
//         border: 1px solid var(--aqua-9) !important;
//         &:hover{
//             background-color: var(--aqua-9);
//             color: white;
//         }
//         &:focus{
//             background-color: transparent ;
//             &:hover{
//                 color: var(--aqua-9);
//             }
//         }
//     }
//     .MuiButton-root.saveWithOtp{
//         color: white !important;
//         background-color: var(--aqua-9) !important;
//         text-transform: none;
//         border-radius: 50px;
//         border: 1px solid var(--aqua-9) !important;
//         &:hover{
//             filter: brightness(80%);
//         }
//     }
//     .MuiGrid-root{
//         h6{
//             color: var(--aqua-9) !important;
//         }
//     }
// }

// .modal-dialog .modal-content{
//     // top: 160px;
//     .modalcomman .modal-content .modaltitle span{
//         color: var(--aqua-9);
//     }

//     .modal-body{
//         .modalfrm h6,span{
//             color: var(--aqua-1);
//         }
//         .modalfrm .custome-input .MuiInput-formControl input{
//             padding-left: 10px !important;
//             color: var(--aqua-9);
//         }
//         .consentsfrm .custome-input .MuiInput-formControl{
//             border-radius: 50px;
//         }
//     }
// }