.carheaderplan {
  position: sticky !important;
  top: 0;
  z-index: 999 !important;
}

.carheaderplan,
.addvehiclebox,
.addownervehiclebox,
.regnumberbox {
  @include boxshow;
  padding: 20px 30px;
  box-shadow: 0px 0px 16px $deadsea;
  .vehicleplanhed {
    padding: 23px 0 0 0;
    h2 {
      font-weight: bold;
      color: $lightblack;
      font-size: 24px;
    }
    p {
      font-size: 14px;
      font-weight: 600;
      color: $lightblack;
      margin-bottom: 0;
    }
  }
  .vehicleplan {
    min-height: 100px;
    background: url("../../../Images/floterplanBg.svg");
    background-size: cover;
    border-radius: 10px;
    color: $white;
    padding: 20px;
    span {
      font-size: 18px;
      padding: 0 0 5px;
      display: inherit;
    }
    p {
      font-size: 14px;
      margin-bottom: 0;
      cursor: pointer;
      i {
        padding-left: 10px;
        font-size: 14px;
        font-weight: bold;
        vertical-align: bottom;
      }
    }
    strong {
      font-size: 24px;
    }
  }
  .addvejicletitle {
    border-bottom: 1px solid rgba($blue, 0.2);
    padding-bottom: 20px;
    font-size: 18px;
    font-weight: bold;
    i {
      padding-right: 10px;
    }
  }
}

.addownervehiclebox {
  .vehcomminput {
    margin-top: 20px;
  }
}
.commantitle {
  padding-top: 40px;
}
.vehicledetailsbox {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 20px 30px;
  box-shadow: 0px 0px 16px $deadsea;
  border-radius: 5px;
  min-height: 510px;
  .vehiclefrm {
    span {
      font-size: 16px;
      font-weight: bold;
    }
    p {
      color: $greyhar;
      font-size: 14px;
    }
  }

  .vehcomminput {
    label {
      font-size: 14px;
    }
  }
}
.vehcommselect {
  margin-top: 40px;
  label {
    font-size: 14px;
    z-index: 1;
  }
  label + .MuiInput-formControl {
    background: $white;
  }
}

.vehcomminputdate {
  margin-top: 6px;
  label,
  input {
    font-size: 14px;
  }
}
.tpcovers {
  margin-top: 30px;
  margin-bottom: 120px;
  padding: 20px 30px;
  box-shadow: 0px 0px 16px $deadsea;
  border-radius: 5px;
  .tpchead,
  .coverstitle {
    width: 100%;
    border-bottom: 1px solid rgba($blue, 0.2);
    font-weight: bold;
    font-size: 18px;
    padding: 10px 0 15px 0;
    .custom-checkbox {
      padding-top: 15px;
      label {
        color: $seblack;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  .coverstitle {
    border-top: 1px solid rgba($blue, 0.2) !important;
    padding-top: 30px !important;
    margin-top: 30px;
  }
}

.commbluecheckbox {
  padding-top: 25px !important;
  padding-left: 24px;
  label {
    color: $black;
    font-size: 14px;
    font-weight: 500;
    padding-top: 2px;
  }
  .custom-control-input:checked ~ .custom-control-label {
    color: $pink;
    &::before {
      color: $white;
      border-color: $pink;
      background-color: $pink;
    }
  }
  .custom-control-input:focus ~ .custom-control-label {
    &::before {
      box-shadow: none;
    }
  }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label {
    &::before {
      border-color: $pink;
    }
  }
}

.regfrm {
  .Proceedbtn {
    border-radius: 5px;
    width: 230px;
    padding: 0.75rem;
    text-decoration: none;
    display: block;
    border: none;
    background: $pink;
    color: $white;
    text-align: center;
    outline: none;

    &.oldcar {
      margin-top: 1.5rem;
    }
  }
  .buttonOR {
    display: block;
    text-align: center;
    margin: 1rem 0;
    font-size: 0.95rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}

.carseletedfooter {
  padding: 15px;
  background: $white;
  box-shadow: 0px 3px 24px $deadsea;
  border-radius: 10px;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 9;

  .Proceedbtn {
    border-radius: 5px;
    padding: 10px 25px;
    text-decoration: none;
    display: inline-block;
    border: none;
    background: $pink;
    color: $white;
    text-align: center;
    margin: 0 5px;
    outline: none;
  }
  .unproceedbtn {
    background: transparent;
    border-radius: 5px;
    padding: 10px 25px;
    text-decoration: none;
    display: inline-block;
    border: 1px solid $pink;
    color: $pink;
    text-align: center;
    margin: 0 5px;
    outline: none;
  }
}

.rangeslider {
  padding: 20px 0;

  &.policy-details-rangeslider {
    padding: 0 0;
  }
  .ragtitle {
    font-size: 14px;
    font-weight: bold;
    color: $lightblack;
    padding: 25px 0 20px;
  }
}

.ownerhedbox {
  padding: 20px 30px;
  background: $white;
  box-shadow: 0px 3px 24px $deadsea;
  border-radius: 10px;
  margin-top: 30px;
  .ownertitle {
    span {
      font-size: 24px;
      color: $lightblack;
      font-weight: bold;
    }
    p {
      font-size: 14px;
      color: $lightblack;
      margin-bottom: 0;
    }
  }
  .pricelist {
    text-align: right;
    span {
      font-size: 24px;
      color: $lightblack;
      font-weight: bold;
    }
    p {
      font-size: 14px;
      color: $lightblack;
      margin-bottom: 0;
    }
  }
}

.ownersubtitle {
  border-bottom: 1px solid rgba($blue, 0.2);
  span {
    font-size: 18px;
    color: $lightblack;
    font-weight: bold;
  }
  p {
    font-size: 14px;
    color: rgba($greyhar, 0.5);
  }
}

.aaimember {
  margin-bottom: 110px;
  .aaisubtitle {
    padding: 20px 0;
    border-bottom: 1px solid rgba($blue, 0.2);
    span {
      font-size: 16px;
      font-weight: bold;
    }
  }
  .aaimemberid {
    border-bottom: 1px solid rgba($blue, 0.2);
    padding-bottom: 20px;
    span {
      padding-top: 20px;
      display: inline-block;
      font-weight: bold;
    }
    .custome-input {
      label {
        font-size: 14px;
      }
    }
  }

  .aaipolicytitle {
    margin-top: 20px;
    padding: 20px 0;
    border-bottom: 1px solid rgba($blue, 0.2);
    border-top: 1px solid rgba($blue, 0.2);
    font-weight: bold;
  }
}

.ownerlist {
  color: $lightblack;
  text-align: left !important;
  font-weight: normal !important;
  list-style: none;
  padding: 0;
  .termscon {
    label {
      font-size: 16px !important;
    }
    @include checkboxcustom;
  }
  p {
    color: $pink;
    display: inline-block;
    padding: 0 0 0 5px;
    margin: 0;
  }
  li {
    border-top: 1px solid $skies;
    padding: 15px 0 0;
    margin-top: 30px;
  }
}

.carselfdetails {
  padding: 20px;
  background: $white;
  box-shadow: 0px 3px 24px $deadsea;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 55px;

  .selfsubtitle {
    span {
      color: $lightblack;
      font-size: 20px;
      font-weight: bold;
    }

    border-bottom: 1px solid rgba($blue, 0.2);
    padding: 0 0 10px;
  }

  .userdetails {
    padding: 20px 0 0;

    span {
      font-size: 1rem;
      font-weight: bold;
    }

    p {
      font-size: 1rem;
      color: rgba($lightblack, 0.8);
      padding: 5px 0;
      margin: 0;
    }
  }

  .custome-input,
  .custome-input-date,
  .custome-select {
    padding: 15px 0 0;
    margin-top: 20px;

    label {
      font-size: 14px;
      z-index: 9;
      // padding-left: 10px;
    }

    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    }

    label + .MuiInput-formControl {
      background: transparent;
    }
  }

  .customemail {
    width: 100%;
    word-break: break-word;
  }

  .selftitle {
    border-bottom: 1px solid $deadsea;
    color: $lightblack;
    margin-bottom: 10px;

    span {
      font-size: 18px;
      font-weight: bold;
    }

    p {
      font-size: 14px;
      padding-bottom: 20px;
      color: $slategray;
    }
  }

  .paybox {
    label {
      margin: 5px 0 0 0;
    }

    .MuiInput-underline:before,
    .MuiInput-underline:hover {
      border-bottom: none !important;
    }

    input {
      border: 1px solid $deyouk;
      border-radius: 5px;
      padding: 10px 15px;
      font-weight: bold;
      height: auto;
    }
  }
}

.regnumberbox {
  .regnumbertitle {
    padding: 10px 0 20px;
    border-bottom: 1px solid rgba($blue, 0.2);
    span {
      font-weight: bold;
      color: $lightblack;
      font-size: 20px;
    }
    p {
      font-size: 14px;
      margin-bottom: 0;
      color: $greyhar;
    }
  }
  .regfrm {
    span {
      padding-top: 30px;
      font-size: 14px;
      font-weight: bold;
      display: inline-block;
    }
    .custome-input {
      padding-top: 10px;
      label {
        font-size: 14px;
      }
    }
  }
  .insurevehicle {
    color: $pink;
    padding-top: 35px;
    font-size: 14px;
    text-align: right;
  }
}

.ragsliderdetails {
  span {
    font-size: 14px;
    font-weight: bold;
  }
  .price {
    text-align: right;
    display: inherit;
  }
}

.unproceedbtn {
  &.styled {
    background-color: $pink;
    color: $white;
  }
}

// owner-details

.addownervehiclebox {
  .custome-select {
    top: 0;
  }

  .vehcommselect {
    margin-top: 20px;
  }
}

// add-vehicle-details

.addvehiclebox {
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
    .MuiAutocomplete-input {
    padding: 6px 6px;
  }

  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 7px 9px;
  }

  .vehcommselect {
    margin-top: 20px;
  }
}

.addownervehiclebox {
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
    .MuiAutocomplete-input {
    padding: 6px 6px;
  }

  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 7px 9px;
  }

  .vehcommselect {
    margin-top: 20px;
  }
}

// Custom Autocomplete Box

.custom-autocomplete {
  .MuiInputBase-root {
  }

  &.Mui-focused {
    .MuiInputLabel-outlined {
      transform: translate(6px, -18px) scale(0.75);
    }

    .PrivateNotchedOutline-legendLabelled-3 {
      width: 0;
    }
  }

  .MuiInputLabel-outlined {
    transform: translate(14px, 16px) scale(1);
  }
}
