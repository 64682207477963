.policysubheader {
  background: $white;
  box-shadow: 0px 3px 54px $sky;
  padding: 10px 0;
  .policybox {
    background: $lightgray;
    border: 1px solid $gray;
    border-radius: 5px;
    width: 100%;
    padding: 0 10px 5px;
    label {
      font-size: 12px;
      color: $gray;
      width: 100%;
      margin-bottom: 0;
    }
    span {
      color: $seblack;
      font-size: 16px;
      font-weight: bold;
      width: 100%;
    }
  }
  .policyboxswitch {
    text-align: right;
    span {
      color: $gray;
      font-size: 12px;
      @include tooltipicongry;
      i {
        width: 16px;
        height: 16px;
      }
    }
    .custom-switch {
      margin: 0 5px 0 15px !important;
    }
    b {
      color: $lightblack;
      font-size: 12px;
      @include tooltipicongry;
      i {
        font-weight: normal;
        width: 16px;
        height: 16px;
      }
    }
    .custom-switch {
      display: inline-block;
      margin: 0 10px 0 20px;
      cursor: pointer;
      .custom-control-input:checked ~ .custom-control-label::before {
        background-color: $pink;
        border-color: $pink;
      }
      .custom-control-input:focus:not(:checked)
        ~ .custom-control-label::before {
        border-color: $blue;
        background-color: $blue;
      }
      .custom-control-label {
        &::after {
          background-color: $white;
        }
        &::before {
          background-color: $blue;
          border-color: $blue;
        }
      }
      .custom-control-input:focus ~ .custom-control-label::before {
        box-shadow: none;
      }
    }
  }
}

.policydetailsmain {
  @include boxshow;
  margin-bottom: 150px;
  border-radius: 25px;
  .policyplanlist {
    margin-bottom: 30px;
    border-bottom: 1px solid rgba($blue, 0.3);
    .policyplantitle {
      h3 {
        font-weight: bold;
        color: var(--aqua-9);
        font-size: 20px;
      }
      p {
        font-size: 12px;
        text-align: left;
        color: var(--aqua-1);
      }
    }
    span {
      color: var(--aqua-9);
      font-size: 22px;
      font-weight: bold;
      text-align: right;
      display: inline-block;
      width: 100%;
    }
    p {
      text-align: right;
      font-size: 12px;
      color: var(--aqua-1);
    }
  }
  .goldplan {
    background: url("../../../Images/Goldplanbg.png");
    background-size: cover;
  }
  .sliverplan {
    background: url("../../../Images/sliverplanbg.png");
    background-size: cover;
  }
  .goldplansingal {
    // background: url("../../../Images/GoldplanSinbg.png");
    background-size: cover;
    padding: 18px 15px;
    border-radius: 25px;
    box-shadow: 0px 3px 14px var(--aqua-1);

    .goldpalntitle {
      font-size: 24px;
      color: var(--mustard-1);
      padding-bottom: 20px;
      padding-top: 10px;
      font-weight: bold;
      .goldpalnprice {
        float: right;
        span {
          text-align: right;
          font-size: 22px;
          font-weight: bold;
          display: block;
          color: rgb(0, 70, 79);
        }
        p {
          font-size: 12px;
          text-align: right;
          color:var(--aqua-1);

          font-weight: normal;
        }
      }
    }

    table {
      padding: 10px 0;
      width: 100%;
      border-bottom: 1px solid rgba($carbon, 0.5);
      border-top: 1px solid rgba($carbon, 0.5);
      tr {
        &:first-child {
          padding: 10px 0;
        }
        td {
          color: var(--aqua-1);
          font-size: 12px;
          padding: 5px 0;
          &:last-child {
            text-align: right;
          }
        }
      }
    }
  }

  .goldplansingal:hover {
    // background: url("../../../Images/GoldplanSinbg.png");
    background-size: cover;
    padding: 18px 15px;
    // border-radius: 10px;
    box-shadow: 0 3px 24px var(--aqua-1);

    .goldpalntitle {
      font-size: 24px;
      color: $mengo;
      padding-bottom: 20px;
      padding-top: 10px;
      font-weight: bold;
      .goldpalnprice {
        float: right;
        span {
          text-align: right;
          font-size: 22px;
          font-weight: bold;
          display: block;
          color: rgb(0, 70, 79);
        }
        p {
          font-size: 12px;
          text-align: right;
          color:var(--aqua-1);

          font-weight: normal;
        }
      }
    }

    table {
      padding: 10px 0;
      width: 100%;
      border-bottom: 1px solid rgba($carbon, 0.5);
      border-top: 1px solid rgba($carbon, 0.5);
      tr {
        &:first-child {
          padding: 10px 0;
        }
        td {
          color: var(--aqua-1);
          font-size: 12px;
          padding: 5px 0;
          &:last-child {
            text-align: right;
          }
        }
      }
    }
  }

  .silverplansingal {
    background-size: cover;
    padding: 18px 15px;
    border-radius: 25px;
    box-shadow:  0px 3px 14px var(--aqua-1);
    .silverpalntitle {
      font-size: 24px;
      color: var(--gray-3);
      font-weight: bold;
      padding-bottom: 20px;
      padding-top: 10px;
      .silverpalnprice {
        float: right;
        span {
          text-align: right;
          font-size: 22px;
          font-weight: bold;
          color: rgb(0, 70, 79);
          display: block;
        }
        p {
          font-size: 12px;
          text-align: right;
          color:var(--aqua-1);
          font-weight: normal;
        }
      }
    }

    table {
      padding: 10px 0;
      width: 100%;
      border-bottom: 1px solid rgba($carbon, 0.5);
      border-top: 1px solid rgba($carbon, 0.5);
      tr {
        &:first-child {
          padding: 10px 0;
        }
        td {
          color: var(--aqua-1);
          font-size: 12px;
          padding: 5px 0;
          &:last-child {
            text-align: right;
          }
        }
      }
    }
  }

  .silverplansingal:hover {
    background-size: cover;
    padding: 18px 15px;
    // border-radius: 10px;
    box-shadow: 0 3px 24px var(--aqua-1);
    .silverpalntitle {
      font-size: 24px;
      color: var(--gray-3);
      font-weight: bold;
      padding-bottom: 20px;
      padding-top: 10px;
      .silverpalnprice {
        float: right;
        span {
          text-align: right;
          font-size: 22px;
          font-weight: bold;
          color: rgb(0, 70, 79);
          display: block;
        }
        p {
          font-size: 12px;
          text-align: right;
          color: var(--aqua-1);
          font-weight: normal;
        }
      }
    }

    table {
      padding: 10px 0;
      width: 100%;
      border-bottom: 1px solid rgba($carbon, 0.5);
      border-top: 1px solid rgba($carbon, 0.5);
      tr {
        &:first-child {
          padding: 10px 0;
        }
        td {
          color: var(--aqua-1);
          font-size: 12px;
          padding: 5px 0;
          &:last-child {
            text-align: right;
          }
        }
      }
    }
  }
  .policygplanbox {
    padding: 10px;
    border-radius: 10px;
    .palntitle {
      font-size: 20px;
      border-bottom: 1px solid rgba($lightwhite, 0.5);
      color: $white;
      padding-bottom: 10px;
      padding-top: 10px;
    }
    table {
      padding: 10px 0;
      display: inline-block;
      width: 100%;
      border-bottom: 1px solid rgba($lightwhite, 0.5);
      tr {
        td {
          color: $white;
          font-size: 12px;
          padding: 5px 0;
          &:last-child {
            text-align: right;
          }
        }
      }
    }
    .planprice {
      font-size: 20px;
      color: $white;
      padding: 10px 0;
      font-weight: bold;
      sub {
        font-size: 10px;
      }
    }
    button {
      background: rgba($white, 0.5);
      border: 1px solid $white;
      border-radius: 5px;
      width: 100%;
      color: $white;
      margin-bottom: 10px;
      text-transform: uppercase;
      outline: none;
    }
  }

  .floterplanbox {
    .flotertitle {
      border-bottom: 1px solid $skies;
      text-align: left;
      margin-top: 50px;
      span {
        font-size: 18px;
        font-weight: bold;
        color: rgb(0, 70, 79);
      }
      p {
        font-size: 12px;
        color: var(--aqua-1);
      }
    }
    .compareplanslink {
      text-align: left;
    }
  }

  .compareplanslink {
    color: rgb(0, 70, 79);
    text-align: right;
    font-size: 12px;
    text-transform: uppercase;
    padding: 30px 0 0;
    font-weight: bold;
    cursor: pointer;
    img {
      width: 24px;
      vertical-align: top;
      padding-right: 10px;
      padding-top: 5px;
    }
    button {
      color: rgb(0, 70, 79);
      background: transparent;
      border: none;
      border: none;
      padding: 0;
      font-weight: 600;
      outline: none;
      font-size: 18px;
      font-weight: 600;
    }
  }
}
.policytitlecheckbox {
  @include policycheckbox;
}

.termdropdown {
  position: relative;
  bottom: 7px;
  h6 {
    font-size: 14px;
  }
  p {
    font-size: 14px;
    color: var(--aqua-1);
  }
  .termddropbtn {
    border: 1px solid $gray;
    border-radius: 5px;
    padding: 8px 15px;
    color: $seblack;
    font-weight: 600;
    font-size: 14px;
    i {
      float: right;
      padding: 7px 0;
    }
  }
  .termlist {
    outline: none;
    width: 100%;
    z-index: 1;
    border-radius: 5px;
    background: $white;
    box-shadow: 0 0px 1px;
    border: 1px solid var(--aqua-9);
    padding: 10px 10px;
    color: var(--aqua-9);
    text-align: center;
    font-size: 18px;
    a,
    option {
      color: $seblack;
      padding: 6px 6px;
      text-decoration: none !important;
      display: block;
      font-size: 18px;
      // font-weight: bold;
      text-align: center;
      span,
      p {
        color: $pink !important;
        font-weight: bold;
        background: rgba($pink, 0.2);
        border-radius: 30px;
        padding: 4px 6px;
        float: right;
        font-size: 10px !important;
        margin-bottom: 0;
      }
      p {
        background: transparent;
        color: $lightblack !important;
        font-size: 12px !important;
      }
      &::hover {
        background-color: #ddd;
        text-decoration: none;
      }
    }
  }

  &:hover .termlist {
    display: block;
  }
}

.policyadonbox {
  border: 1px solid rgba($dugong, 0.5);
  padding: 10px 20px;
  border-radius: 5px;
  .adonheder {
    border-bottom: 1px solid rgba($dugong, 0.5);
    padding-bottom: 10px;
    span {
      color: $lightblack;
      font-weight: bold;
      font-size: 18px;
      &:first-child {
        text-align: left;
      }
      &:last-child {
        text-align: right;
        float: right;
      }
    }
  }
  .addonlist {
    width: 100%;
    tr {
      td {
        .addonchecklist {
          @include policycheckbox;
        }
        font-weight: bold;
        color: $blue;
        font-size: 14px;
        padding: 10px 0;
        &:last-child {
          text-align: right;
        }
        i {
          font-size: 18px;
          padding-left: 7px;
        }
      }
    }
  }
}
.addchoicesbtn {
  position: relative;
  button {
    color: $pink;
    border: 1px solid $pink;
    border-radius: 50px;
    background: $white;
    width: 100%;
    outline: none;
    font-size: 18px;
    &:hover,
    &:focus {
      background: $pink;
      color: $white;
      outline: none;
    }
  }
}

.checklist {
  color: $pink;
  z-index: 1 !important;
  font-size: 14px;
  i {
    color: $pink;
    padding-right: 10px;
    display: inline-block;
    font-size: 18px;
  }
  p {
    display: inline-block;
    font-size: 14px;
  }
}

.addchoiceserror {
  margin-top: 20px;
  position: relative;
  top: 40px;
  button {
    color: $pink;
    border: 1px solid $pink;
    background: $white;
    width: 100%;
    outline: none;
    font-size: 18px;
    &:hover,
    &:focus {
      background: $pink;
      color: $white;
      outline: none;
    }
  }
}
.policyfooter {
  @include fixedfooter;
  position: fixed;
  .payviw {
    button {
      color: $white;
      background: $pink;
    }
  }
  .policywordbtn {
    img {
      position: absolute;
      top: 7px;
      left: 22px;
      width: 25px;
    }
  }
  .policybtn,
  .policywordbtn {
    button {
      color: $pink;
      border: 1px solid $pink;
      background: $white;
      &:hover {
        background: $pink;
        color: $white;
      }
    }
  }
  button,
  .button:focus {
    border-radius: 5px;
    padding: 12px 8px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    outline: none;
    font-size: 14px;
  }
}

.clientpopup {
  .modalfrm {
    text-align: center;
    .custome-input {
      position: relative;
      border: 1px solid $deyouk;
      margin: 10px 0;
      padding: 0 15px;
      border-radius: 5px;
    }
    .MuiInputLabel-formControl {
      top: -8px;
    }
    .MuiFormLabel-root.Mui-focused {
      top: 0px;
    }
    .MuiInput-underline:before {
      border-bottom: 0;
    }
    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: none;
    }
    .MuiInput-underline:after {
      // border-bottom: 1px solid $lightblack;
    }
  }
  .modal-content {
    border: none;
  }
  .modal-header {
    text-align: center;
    width: 100%;
    display: inline-block;
    border-bottom: none;
    padding: 0;
    .modaltitle {
      span {
        color: $lightblack;
        font-size: 24px;
        font-weight: bold;
      }
    }
  }
  button,
  button:focus {
    background: $pink;
    color: $white;
    border: none;
    padding: 15px 45px;
    outline: none;
    margin-top: 15px;
    text-transform: uppercase;
    outline: none;
  }
}

.paymentMethodsbtn {
  border: 1px solid var(--aqua-9) !important;
  border-radius: 25px;
  background: transparent;
  padding: 10px;
  width: 100%;
  color: var(--aqua-9) !important;
  &:hover {
    background: var(--aqua-9) !important;
    border: none;
    color: $white !important;
  }
}

.goldpalndata {
  span {
    font-size: 12px;
    font-weight: bold;
    color: var(--aqua-1);
    // line-height: 3px;
  }
  p {
    font-size: 12px;
    text-align: right;
    color: #212529;

    font-weight: normal;
  }
}

.silverpalndata {
  overflow-x: auto;
  span {
    font-size: 12px;
    font-weight: bold;
    color: var(--aqua-9);
    text-align: left;
  }
  p {
    font-size: 12px;
    text-align: right;
    color: #212529;

    font-weight: normal;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto  ;
}
.grid-item {
  background-color: rgba(255, 255, 255, 0.8);

  text-align: left;
}
.grid-item2 {
  background-color: rgba(255, 255, 255, 0.8);

  text-align: right;
}

.MuiRadio-colorSecondary.Mui-checked {
  color:var(--aqua-7) !important;
}
.MuiIconButton-colorSecondary {
  color: var(--coral-4) !important;
}
.MuiTypography-body1 {
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: var(--aqua-9);
}
