.lastquestionbox {
  @include boxshow;
  margin-top: 100px;
  margin-bottom: 80px;
  border-radius: 25px;

  .close-add-member {
    // position: absolute;
    // top: 10px;
    // right: 0px;
    // color: $pink;
    // width: 100%;
    display: inline-block;
    text-align: left;
    padding: 3px 14px;
    font-size: 20px;

    i {
      color: $pink;
      opacity: 1;

      &::before {
        // color: $carbon;
        // border-radius: 50%;
        // border: 1px solid;
        // font-size: 12px;
        // margin-right: 5px;
        // padding: 3px;
      }
    }
  }

  .questiontitle {
    border-bottom: 1px solid $skies;
    padding: 0px 0 10px;

    span {
      font-size: 20px;
      color: var(--aqua-9);
      font-weight: bold;
    }

    p {
      font-size: 12px;
      color: var(--aqua-1);
    }
  }

  .addquestion {
    padding: 20px 0;
    border-bottom: 1px solid $skies;
    .custome-select {
      > {
        border: 1px solid $deyouk;
        border-radius: 5px;
        padding: 15px 5px;
        height: auto;
        font-weight: bold;
      }
    }
    span {
      font-weight: bold;
      font-size: 16px;
      color: $lightblack;
    }

    p {
      font-size: 14px;
      color: $gray;
      margin-bottom: 0;
    }

    .followingchecklist {
      @include policycheckbox;
      padding: 4px 0;
    }

    .checklist {
      @include policycheckbox;
    }

    .removebtn {
      color: rgba($carbon, 0.6);
      background: transparent;
      border: none;
      font-weight: normal;
      font-size: 14px;
      padding: 0;
      text-align: right;
      outline: none;

      i {
        padding-right: 5px;
      }
    }
  }

  .medicalfollowing {
    margin: 30px 0;

    h3 {
      font-size: 16px;
      color: var(--aqua-1);
      margin: 0;
    }

    span {
      color: $lightblue;
      text-align: right;
      font-size: 14px;
      float: right;
      font-weight: bold;
    }

    .followingchecklist {
      @include policycheckbox;
      padding: 15px 0;
      word-break: break-all;
    }

    .medicalcondition {
      button {
        border: 1px solid rgba($lightblack, 0.1);
        padding: 10px 15px;
        border-radius: 5px;
        background: transparent;
        color: rgba($seblack, 0.4);

        &:hover {
          color: $white;
          background: $blue;
        }
      }
    }

    .uploadedFiles{
      box-shadow: 0px 3px 14px var(--aqua-1);
      border-radius: 25px;
      padding: 1rem;
      margin-top: 10px;
      .doccontainer{
        max-width: 1000px;
      }
    }

    .browseBtn button{
      margin-left: 10px;
      margin-right: 10px;
      border-radius: 25px;
      padding: 10px 20px;
      background-color: white;
      color: var(--aqua-9);
      &:hover{
        color: white;
        background-color: var(--aqua-9);
      }
    }
  }
}

.addtoanother {
  background: white;
  padding: 30px;
  border-radius: 25px;
  color: var(--aqua-9);
  margin: 0 0 100px;
  box-shadow: 0 3px 14px var(--aqua-1);

  span {
    font-size: 20px;
    font-weight: bold;
  }

  p {
    font-size: 12px;
    margin-bottom: 0;
    color: var(--aqua-1);
  }

  a {
    font-size: 14px;
    color: $blue;
    border: 1px solid;
    border-radius: 5px;
    display: inline-block;
    padding: 8px 15px;
    text-decoration: none;
    margin: 7px 0 0;
    width: 100%;
    text-align: center;

    &:hover {
      background: $blue;
      color: $white;
      text-decoration: none;
    }
  }

  .undefined {
    border: 1px solid var(--aqua-9);
    border-radius: 50px;
    padding: 10px 15px;
    width: 100%;
    background: transparent;
    color: var(--aqua-9);
    outline: none;

    &:hover {
      background: var(--aqua-9);
      color: $white;
    }
  }
}

.commanscroll {
  margin: 30px 0;
  height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.diseasequestion {
  b {
    margin-top: 10px;
    padding: 0px 15px 30px;
    display: inline-block;
    color: $blue;

    font-size: 20px;
    color: $lightblack;
    font-weight: bold;
  }


  padding: 0px 0 10px;

  span {
    font-size: 20px;
    color: $lightblack;
    font-weight: bold;
  }

  p {
    font-size: 12px;
    color: $slategray;
  }
}

// .selected{
//   color: red;
// }
