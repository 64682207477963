.medicalconditionbox {
  @include boxshow;
  margin-top: 60px;
  .medicalcontitle {
    border-bottom: 1px solid $skies;
    padding: 0px 0 10px;
    span {
      font-size: 20px;
      color: $blue;
      font-weight: bold;
    }
    p {
      font-size: 12px;
      color: $blue;
    }
  }
  .medicalquestion {
    padding: 20px 0;
    border-bottom: 1px solid $skies;
    span {
      font-weight: bold;
      font-size: 16px;
      color: $lightblack;
    }
    p {
      font-size: 14px;
      color: $gray;
      margin-bottom: 0;
    }
    .checklist {
      @include policycheckbox;
    }
    button {
      color: rgba($carbon, 0.6);
      background: transparent;
      border: none;
      font-size: 12px;
      padding: 0;
      text-align: right;
      outline: none;
    }
  }

  .medicalfollowing {
    margin: 30px 0;
   
   
    
   
    h3 {
      font-size: 16px;
      color: $lightblack;
      margin: 0;
    }
    span {
      color: $lightblue;
      text-align: right;
      font-size: 14px;
      float: right;
    }
    .medicalchecklist {
      @include policycheckbox;
      padding: 30px 0;
    }
    .medicalcondition {
      button {
        border: 1px solid rgba($lightblack, 0.1);
        padding: 10px 15px;
        border-radius: 5px;
        background: transparent;
        color: rgba($seblack, 0.4);
        outline: none;
      }
    }
  }
}

.medicalanother {
  background: $deuwhite;
  padding: 30px;
  border-radius: 5px;
  color: $blue;
  span {
    font-size: 20px;
    font-weight: bold;
  }
  p {
    font-size: 12px;
    margin-bottom: 0;
  }
  a {
    font-size: 14px;
    color: $pink;
    border: 1px solid;
    border-radius: 5px;
    display: inline-block;
    padding: 8px 15px;
    text-decoration: none;
    margin: 7px 0 0;
    width: 100%;
    text-align: center;
    &:hover {
      background: $pink;
      color: $white;
      text-decoration: none;
    }
  }
}
.selected{
  color: var(--aqua-9);
}