.termsmain {
  padding: 50px 0 100px;
  button {
    background: transparent !important;
    outline: none !important;
    i {
      color: $pink;
      font-size: 20px;
      position: relative;
      left: -8px;
      top: 0px;
    }
  }
  h1 {
    font-size: 28px;
    color: $blue;
  }
  span {
    font-size: 18px;
    font-weight: bold;
    padding: 10px 0;
    display: inline-block;
  }
  p {
    font-size: 14px;
    ol {
      li {
        margin: 5px 0;
      }
    }
  }
}
.termsfooter {
  padding: 20px;
  background: $white;
  box-shadow: 0px 3px 24px $deadsea;
  border-radius: 10px;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 1;
  button,
  button:hover {
    background: $pink;
    color: $white;
    border-radius: 5px;
    padding: 10px 20px;
  }
}
