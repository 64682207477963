// qq -> quick quote
// pd -> policy details
// sp -> selected plan
// nd -> nominee details
// cdbox -> contact details box
// dp -> discounts page
// qs -> quick summary
// id -> insured data page
// port-pd-mb -> portability - proposal details page - margin bottom
// mae -> Member details Add or Edit
// mmod -> made our day page
// ad -> address details page
// marBot-ls -> margin bottom landscape
// marBot-ls-0 -> margin bottom landscape 0px

@media screen and (max-width: 766px) {
    .quickquote-mediaquery1, .quickquote-mediaquery2 {
        margin-top: 80px !important;
    }

    .lastquestionbox-mediaquery {
        margin-top: 100px !important;
    }

    .topuppolicysubheader-mediaquery, .topuppolicysubheader-mediaquery2 {
        margin-top: 100px;
    }

    .plan-top-box-mediaquery {
        margin-top: 130px;
    }

    .plan-card-2-mediaquery {
        margin-top: 20px;
    }

    .proposaldetailsbox-mediaquery {
        margin-bottom: 200px !important;
    }

    .quick-summary-last-box-mediaquery {
        margin-bottom: 180px;
    }
    .policydetailsmain-mediaquery, .policydetailsmain-mediaquery2 {
        margin-bottom: 245px;
    }

    .fields-spacing-mediaquery {
        padding:20px 0px;
    }

    .qq-selectfield-mediaquery {
        padding: 0px 3px;
        margin-bottom: 7px;
    }

    .pd-footerbtn-mediaquery, .sp-footerbtn-mediaquery, .qs-footerbtn-mediaquery, .ped-skipbtn-mediaquery, .footer-savebtn-mb-mediaquery {
        margin-bottom: 5px;
        button {
            padding-top: 6px;
            padding-bottom: 6px;
        }
    }

    .ped-footerbtn-mediaquery {
        float: none !important;
    }

    .ped-footer-sentence {
        margin: auto 0px;
    }

    .port-pd-mb-mediaquery {
        margin-bottom: 100px;
    }

    .port-pd-footerbtnorder-mediaquery {
        flex-direction: column-reverse;
    }

    .plan-div-suminsured-mediaquery, .plan-div-year-mediaquery, .plan-div-premium-mediaquery {
        text-align: left !important;
    }

    .plan-div-premiumtext-mediaquery, .plan-tenure-mediaquery, .plan-div-sitext-mediaquery {
        justify-content: flex-start !important;
    }

    .qq-switch-mediaquery {
        display: flex;
        margin-top: 20px;
    }

    .qq-policytype-mediaquery {
        text-align: left !important;
        margin-top: 20px;
    }

    .sp-sivalue-mediaquery, .pd-sivalue-mediaquery {
        text-align-last: left;
    }

    .dp-yes-no-div-mediaquery {
        margin-top: 0px !important;
    }

    .yes-no-text-mediaquery {
        display: contents;
        input {
            margin-left: 15px;
            margin-top: 6px;
        } 
    }

    .terms-mediaquery {
        margin-top: 140px;
    }

    .tollfree-header-mediaquery {
        display: none !important;
    }

    .hamburger-mediaquery {
        padding-top: 13px !important;
        position: absolute;
        left: -10px;
    }

    .mae-popup-mediaquery{
        padding: 0px 20px 50px 20px;
    }

    .mae-checkbox-mediaquery {
        padding-left: 40px !important;
        margin-top: 20px !important;
    }

    .mae-floater-select-mediaquery {
        padding: 0px 20px !important;
        margin-bottom: 15px;
    }

    .PreMedicalpopup-mediaquery {
        margin-top: 0px !important;
    }

    .ped-popup-btndiv-mediaquery {
        margin: 0px auto 20px auto !important;
    }

    .pricetable-mediaquery {
        tabel tr td:first-child {
            min-width: 0px !important;
        }
    }

    .policyplanlist-mediaquery {
        margin-bottom: 0px !important;
    }

    .nd-text-mediaquery {
        display: flex;
        margin-top: 10px;
    }

    .consent-payment-btn-mediaquery {
        margin-left: 0px !important;
    }

    .id-btn-mediaquery {
        margin-bottom: 7px;
    }

    .payment-page-mediaquery {
        margin-top: 100px;
    }

    .paymentaddbutton-mediaquery {
        margin-bottom: 150px !important;
    }

    .cheque-fields-mediaquery, .cash-fields-mediaquery {
        .custome-input,.custome-input-date {
            padding: 0px;
        }
    }

    .neft-fields-mediaquery {
        .custome-input, .custome-input-date, .custome-select {
            padding: 0px;
        }
    }

    .mod-box-mediaquery {
        margin-top: 90px !important;
    }

    .sorry-box-mediaquery {
        margin-top: 110px !important;
    }

    .thankyoubox-mediaquery, .thankyoupage-box-mediaquery, .paylater-box-mediaquery {
        margin-top: 130px !important;
    }

    .thankyou-page-imgdiv-mediaquery {
        display: none;
    }

    .id-footer-btndiv-mediaquery {
        width: 100%;
    }

}

@media screen and (max-width: 768px) {
    .mae-selectfield-mediaquery {
        padding: 0px 20px !important;
        margin-bottom: 16px;
    }

    .mae-closebtn-mediaquery {
        margin-bottom: 10px;
    }

    .qq-silabel-mediaquery {
        margin-top: 15px;
        margin-right: 30px;
        right: -20px;
    }

    .preferredsum {
        display: contents;
    }

}

@media (min-width:768px) and (max-width: 992px) {
    .fields-spacing-mediaquery {
        padding:20px 0px;
    }

    .port-pd-mb-mediaquery {
        margin-bottom: 100px;
    }

    .plan-div-year-mediaquery {
        text-align: left !important;
    }

    .plan-tenure-mediaquery {
        justify-content: flex-start !important;
    }

    .plan-div-sitext-mediaquery {
        padding-right: 0px !important;
    }

    .failure-img-mediaquery {
        max-width: 160% !important;
        width: 160%;
        right: 57px !important;
    }
    
    .ped-footer-btns-mediaquery, .pd-footerbtn-mediaquery {
        margin-bottom: 6px;
    }
}

@media screen and (min-width: 992px) {
    .failure-img-mediaquery {
        max-width: 100% !important;
        right: -50px !important;
    }
}
@media screen and (min-width: 768px) {
    .port-pd-footerbtnorder-mediaquery {
        flex-direction: row-reverse;
    }

    .tollfreenumber-callicon-mq {
        display: none;
    }

    .qq-switch-mediaquery {
        margin-top: 3px;
    }
}

@media screen and (min-width: 1200px) {
    .plan-div-suminsured-mediaquery {
        padding-right: 0px !important;
    }
    
    .plan-div-sivalue-mediaquery {
        margin-right: 0px !important;
    }
}


@media screen and (max-width: 575px) {
    .qq-text-mediaquery {
        text-align: left !important;
        margin-top: 15px;
    }
    
    .mae-text-mediaquery {
        text-align: left !important;
        margin-top: 10px;
    }

    .payment-cancelbtn-mediaquery {
        margin-bottom: 6px;
    }

    .payment-btn-order-mediaquery {
        flex-flow: wrap-reverse !important;
    }
}

@media screen and (max-width: 576px) {
    .mod-imgdiv-mediaquery {
        display: none;
    }

    .addons-popup-mediaquery {
        margin-bottom: 100px;
    }

    .addons-footer-mediaquery {
        position: sticky;
    }

    .med-cond-text-mediaquery {
        text-align: center !important;
    }
}

@media screen and (max-width : 991px) {
    .cdbox-text-mediaquery {
        display: contents;
    }

    .last-question-yes-no-mediaquery, .ped-popup-yes-no-mediaquery {
        margin-top: 10px;
    }

    .mae-sitable-mediaquery {
        left: 0px !important;
    }

    .mae-si-mediaquery .custome-select .MuiFormControl-root .MuiInput-root,
    .mae-decutible-mediaquery .custome-select .MuiFormControl-root .MuiInput-root {
        margin-top: 0px;
    }

    .payment-progress-imgdiv-mediaquery, .thankyouuser-imgdiv-mediaquery {
        display: none;
    }

    .consent-payment-btn-mediaquery {
        margin-left: 0px !important;
    }

    .lastquestionbox-mediaquery {
        margin-bottom: 230px !important;
    }

    .pd-footer-mediaquery {
        flex-direction: column-reverse;
    }


    .ekyc-text-mediaquery {
        padding-top: 8px ;
        padding-bottom: 8px;
    }

    .id-backbtn-mediaquery {
        margin-bottom: 6px;
    }

    .ped-sub-heading {
        font-size: 20px;
        margin-bottom: 0px !important;
    }

    .plan-title, .plan-div-suminsured-mediaquery span,
    .plan-div-year-mediaquery span, .plan-div-premium-mediaquery span {
        font-size: 18px !important;
    }
}

@media screen and (min-width : 991px) {
    .pd-footer-mediaquery {
        flex-direction: row-reverse;
    }
}

@media screen and (min-width:767px){
    .header-blank-mediaquery {
        display: none;
    }

    .nd-footer-mediaquery, .ad-footer-mediaquery, .dp-footer-mediaquery {
        flex-direction: row-reverse;
    }
}

@media screen and (max-width: 444px) {
    .mae-si-mediaquery .custome-select .MuiFormControl-root .MuiInput-root,
    .mae-decutible-mediaquery .custome-select .MuiFormControl-root .MuiInput-root {
        min-width: 0px !important;
    }

    .failure-detailsbox-mediaquery {
        padding-bottom: 10vh;
    }

    .MuiMenu-paper{
        .MuiMenu-list {
            .MuiMenuItem-root.MuiMenuItem-gutters{
                border-bottom: 1px solid var(--gray-2);
                text-wrap: wrap;
                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
}

@media screen and (max-width:369px) {
    .qs-yes-no-mediaquery {
        display: flex;
    }

    .hamburger-div-mediaquery {
        max-width: 22% !important;
        text-align-last: right !important;
    }

    .zunologo-div-mediaquery {
        padding-right: 0px !important;
    }
}

@media screen and (max-width:768px) {
    .header1 {
        display: none;
    }

    .failure-imgdiv-mediaquery {
        display: none;
    }

    .sorry-box-mediaquery {
        padding-top: 12vh;
    }

    .paylater-imgdiv-mediaquery {
        display: none;
    }
}

@media  screen and (min-width:769px) {
    .header2 {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .proposalaction-mediaquery {
        width: 440px !important;
    }

    .payment-progress-detailsbox-mediaquery {
        margin-top: 140px;
    }

    .page-title-mediaquery {
        margin-top: 40px !important;
        h2 {
            font-size: 20px !important;
        }
    }

    .pl-rm {
        padding-left: 0px !important;
    }

    .nd-footer-mediaquery, .ad-footer-mediaquery, .dp-footer-mediaquery {
        flex-direction: column-reverse;
    }
}

@media screen and (max-width: 500px) {
    .progress-mediaquery {
        min-width: 258px !important;
        max-width: 258px !important;
    }
    .proposalaction-mediaquery {
        width: 258px !important;
        a:nth-child(3)::before {
            width: 56% !important;
        }
        a:nth-child(4)::after {
            width: 36px !important;
        }
        a:nth-child(4)::before {
            width: 0px !important;
        }
    }

    .nd-page-progress-mediaquery, .pd-page-progress-mediaquery {
        a:nth-child(2)::before {
            width: 56% !important;
        }
    }

    .textIndent-0 {
        text-indent: 0px !important;
        td:nth-child(1) {
            padding-left: 15px !important;    
        }
    }
}


@media screen and (max-width: 886px) {
    .madeoudayrtitle-mediaquery::before {
        border-bottom: 0px !important;
    }
    
}

@media screen and (max-width: 1199px) {
    .thankyouuser-img-mediaquery {
        margin-left: -156px !important;
    }

    .id-footer-btndiv-mediaquery {
        text-align: center !important;
    }
    
    .takeconsentbtn, .proceed-btn-mediaquery {
        margin-left: 0px !important;
    }

    .id-btn2-mediaquery {
        margin-bottom: 7px;
    }

    .copyLinkBtn {
        margin-bottom: 5px;
        margin-left: 0px;
    }
}

@media screen and (max-width: 450px) {
    .address-checkbox-mediaquery {
        margin-top: 15px;
        input{
            margin-right: 5px !important;
        }
        label{
            margin-left: 0px !important;
            display: contents;
        }
    }
    
}


@media screen and (max-width:991.98px) {
    .ped-footer-btns-mediaquery {
        button {
            padding-bottom: 6px !important;
            padding-top: 6px !important;
        }
    }
}

@media screen and (max-width:767.98px) {
    .ad-addressbox-mediaquery, .dp-box-mediaquery {
        margin-bottom: 160px !important;
    }

    .id-footer-btndiv-mediaquery button, .DiscountsSubmitBtn, .addressDetailsProceedBtn,
    .nomineeDetailsProceedBtn, .proposalDetailsProceedBtn, .quickSummaryProceedBtn,
    .selectedPlanProceedBtn, .pedProceedBtn, .pd-shareQuoteBtn, .portProceedBtn {
        padding-bottom: 6px !important;
        padding-top: 6px !important;
    }

    .headeruser-mq {
        margin: 0px !important;
        max-width: none !important;
    }

    .tollfreenumber-callicon-mq {
        text-align: end;
        font-size: 33px;
        color: var(--aqua-9);
    }

    .close-btn-right {
        right: auto !important;
    }
}

@media (max-height: 500.98px) {
    .header-mq {
        position: absolute;
    }

    .footer-mq {
        position: relative !important;
    }

    .marBot-ls {
        margin-bottom: 25vh !important;
    }

    .marBot-ls-0 {
        margin-bottom: 0px !important;
    }

    .plan-top-box-mediaquery {
        margin-top: 30vh;
    }

}

@media (max-width:305.98px) {
    .qq-pad-0 {
        padding: 0px !important;
    }
}

@media (max-width:338.98px) {
    .noBtn-marTop {
        margin-top: 10px !important;
    }
    #nomineeRelationId {
        font-size: 13px;
        top: 1px;
    }

    .header-popup-text {
        span, p {
            font-size: 12px !important;
        }
    }
}

@media (max-width: 314.98px) {
    #policyNumId1-label, #policyNumId2-label {
        font-size: 13px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    #policyNumId1-label, #policyNumId2-label {
        font-size: 15px;
    }
}

@media (max-width: 380px) {
    .port-comp-text-setting {
        .custome-input-date, .custome-input, .custome-select {
            label {
                font-size: 12px;
                top: 3px;
            }
            label.MuiInputLabel-shrink {
                text-wrap: nowrap;
                top: 0px;
                font-size: 1rem;
            }
        }
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    #nomineeRelationId {
        font-size: 13px;
        top: 1px;
        &.MuiInputLabel-shrink{
            font-size: 0.9rem;
        }
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    #nomineeRelationId {
        font-size: 15px;
    }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
    .port-prevPol-prod-name {
        .custome-input label{
            font-size: 14px;
            top: 1px;
        }
    }
}

@media (max-width: 575.98px) {
    // below class will set the overflow as "auto" upto width size 575.98px
    .comp-overflow-auto {
        overflow-x: auto;
    }
}

@media screen and (max-width: 429.98px) {
    .disclaimer-content {
        margin-left: 20px !important;
    }
}