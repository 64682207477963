.goldplantopbox {
  padding: 20px;
  background: $white;
  box-shadow: 0px 3px 14px var(--aqua-1);
  border-radius: 10px;
  margin-top: 120px;
  .goldplantitle {
    font-size: 24px;
    color: var(--aqua-1);
    font-weight: bold;
    span {
      color: $mengo;
      padding-left: 5px;
    }
    p {
      font-size: 12px;
      color: var(--aqua-1);
      margin: 0;
    }
  }
  .goldpricelist {
    text-align: right;
    i {
      vertical-align: text-top;
      color: $cerebralgrey;
    }
    span {
      font-size: 24px;
      color: $lightblack;
      font-weight: bold;
    }
    p {
      font-size: 12px;
      color: $lightblack;
      padding-right: 15px;
      margin: 0;
    }
  }
  .goldplantitlesmall {
    font-size: 20px;
    color: $lightblack;
    font-weight: bold;
    span {
      color: $mengo;
      padding-left: 5px;
    }
    p {
      font-size: 10px;
      color: $lightblack;
      margin: 0;
    }
  }
  .goldpricelistsmallsize {
    text-align: right;
    i {
      vertical-align: text-top;
      color: $cerebralgrey;
    }
    span {
      font-size: 18px;
      color: var(--aqua-9);
      font-weight: bold;
    }
    p {
      font-size: 12px;
      color: var(--aqua-1);
      padding-right: 15px;
      margin: 0;
    }
  }
}
.goldquicksummarybox {
  padding: 20px;
  background: $white;
  box-shadow: 0px 3px 24px $deadsea;
  border-radius: 10px;
  margin-top: 40px;
  .goldquicksummarytitle {
    border-bottom: 1px solid $skies;
    padding: 0px 0 10px;
    span {
      font-size: 20px;
      color: var(--aqua-9);
      font-weight: bold;
    }
    p {
      font-size: 12px;
      color: var(--aqua-1);
    }
  }
  .goldquicksummarylist {
    .termslink {
      color:var(--aqua-9);
      text-align: left !important;
      font-weight: normal !important;
      list-style: none;
      padding: 0;
      .termscon {
        label {
          font-size: 16px !important;
          
        }
        @include checkboxcustom;
      }
      p {
        color: $pink;
        display: inline-block;
        padding: 0 0 0 5px;
        margin: 0;
      }
      li {
        border-bottom: 1px solid var(--gray-2);
        // border-top: 1px solid var(--aqua-1);
        padding: 15px 0;
      }
    }
    table {
      width: 100%;
      tr {
        padding: 10px 0;
        border-bottom: 1px solid $skies;
        td {
          color: $black;
          font-size: 14px;
          padding: 15px 0;

          &:last-child {
            text-align: right;
            font-weight: bold;
          }
        }
      }
    }
  }
}
.goldlast {
  margin-bottom: 120px;
}
.goldplanfooter {
  @include fixedfooter;
  position: fixed;
  .paynow {
    button {
      color: $white;
      border-radius: 50px;
      background: var(--aqua-9);
      font-size: 14px;
    }
  }
  .paylettrbtn,
  .sharebtn {
    button {
      color: var(--aqua-9);
      border: 1px solid var(--aqua-9);
      background: $white;
      font-size: 14px;
      &:hover {
        background: var(--aqua-9);
        color: $white;
      }
    }
  }
  button,
  .button:focus {
    border-radius: 5px;
    padding: 12px 8px;
    border: 1px solid $pink;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    outline: none;
    font-size: 14px;
  }
}
