.priceplan {
  @include boxshow;
  box-shadow: none;
  margin-bottom: 170px;
  margin-top: 0;
  max-width: 1080px !important;
  margin-left: auto;
  margin-right: auto;
}
.goldpalntop {
  margin-top: 110px;
}
.pricetabel {
  tabel {
    width: 100%;
    display: inline-table;
    box-shadow: 0px 3px 14px var(--aqua-1);
    border-radius: 20px;
    padding: 10px;
    tr {
      margin: 0 15px;
      th {
        
        &:nth-child(3) {
          background: linear-gradient(to right, #0c4da2, #5295ec) !important;
          left: 15px;
          position: relative;
        }
        &:nth-child(2) {
          background: $mengogradient !important;
        }
        &:nth-child(2),
        &:nth-child(3) {
          color: var(--aqua-9);
          // color: $white;
          padding: 15px 20px;
          text-align: center;
          border-radius: 8px;
          p {
            margin: 0;
            padding: 0;
            font-size: 14px;
            font-weight: normal;
            // color: var(--mustard-1);
          }
          span {
            font-size: 24px;
            font-weight: bold;
            color: var(--aqua-9);
          }
        }
        &:nth-child(2){
          p{
            color: var(--mustard-1);
          }
        }
        &:nth-child(3){
          p{
            color: var(--gray-3);
          }
        }
      }
    }
    tr {
      td {
        position: relative;
        &:first-child {
          color: var(--aqua-9);
          padding: 10px 0;
          font-size: 14px;
          border-top: 1px solid rgba($lightblack, 0.1);
          i {
            // color: $deyouk;
            padding-left: 10px;
            background: $gray;
            color: $white;
          }
          min-width: 260px;
        }
        &:nth-child(2) {
          background: $lightgray;
          padding-top: 15px;
          color: var(--aqua-1);
          text-align: center;
         
        }
        &:nth-child(3) {
          left: 15px;
          padding: 10px 0;
          color: var(--aqua-1);
          text-align: center;
        }
        &:nth-child(2),
        &:nth-child(3) {
          i {
            color: $grass;
            font-size: 20px;
            margin: 0 auto;
            text-align: center;
            width: 100%;
            min-width: 200px;
          }
        }
      }
    }
  }
  button {
    color: $pink;
    border: none;
    background: transparent;
    font-weight: bold;
    font-size: 22px;
    position: absolute;
    right: 40px;
    display: inline-block;
    top: 0px;
    &:focus {
      outline: none;
    }
  }
}

.goldpricetabel {
  width: 100%;
  display: inline-table;
  tr {
    margin: 0 15px;
  }

  tr {
    &:nth-child(1) {
      td {
        font-size: 18px !important;
        padding: 40px 0 !important;
        i {
          color: $terminalgreen !important;
          padding: 10px !important;
        }
      }
    }
    td {
      position: relative;
      &:first-child {
        color: $lightblack;
        padding: 10px 0;
        font-size: 14px;
        border-top: 1px solid rgba($lightblack, 0.1);
        i {
          // color: $deyouk;
          padding-left: 10px;
          background: $gray;
          color: $white;
        }
      }
    }
  }
}

.goldplaninc {
  background: $goldlight;
  text-align: center;
  padding: 40px 0;
  min-height: 550px;
  position: relative;
  h3 {
    color: $white;
    font-weight: bold;
  }
  .goldplanincprice {
    color: $white;
    position: absolute;
    bottom: 20px;
    width: 100%;
  }
  span {
    font-size: 20px;
    font-weight: bold;
  }
  p {
    font-size: 14px;
  }
  button {
    background: $pink;
    color: $white;
    border: none;
    padding: 10px 18px;
  }
}

.exclusions {
  h3 {
    color: $lightblack;
    font-weight: bold;
    font-size: 18px;
    padding: 20px 10px 66px;
    i {
      color: $pink;
      padding: 0 15px 0 0;
    }
  }
}

.exclusionslist {
  tabel {
    tr {
      td {
        color: $lightblack !important;
        padding: 10px 0 !important;
        font-size: 14px !important;
        border-top: 1px solid rgba($lightblack, 0.1) !important;
      }
    }
  }
}

.inclusionsfooter {
  padding: 20px;
  background: $white;
  box-shadow: 0px 3px 24px $deadsea;
  margin: 0 auto;
  width: 100%;
  text-align: left;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 1;
  tabel {
    width: 100%;
    display: inline-table;
    tr {
      margin: 0 15px;
      th {
        button {
          color: $white;
          background: transparent;
          border: none;
        }
        padding-left: 30px;
        font-size: 20px;
        b {
          text-transform: uppercase;
          font-size: 14px;
          float: left;
          font-weight: normal;
          padding: 18px 10px 0 0px;
        }
        .footerprice {
          display: inherit;
          i {
            position: absolute;
            right: 10px;
            top: 20px;
          }
          span {
            font-size: 20px;
          }
          p {
            margin: 0;
            font-size: 10px;
            font-weight: normal;
          }
        }
        &:nth-child(3) {
          background: $sliverplan !important;
          left: 5px;
          position: relative;
        }
        &:nth-child(2) {
          background: $mengogradient !important;
          right: 10px;
          position: relative;
        }
        &:nth-child(2),
        &:nth-child(3) {
          color: $white;
          text-align: left;
          border-radius: 8px;
          width: 245px;
          padding: 10px 10px;
        }
      }
    }
  }
}

.inclusiontable {
  tabel {
    width: 100%;
    display: inline-table;
    tr {
      margin: 0 15px;
      th {
        &:nth-child(3) {
          background: linear-gradient(to right, #0c4da2, #5295ec) !important;
          left: 15px;
          position: relative;
        }
        &:nth-child(2) {
          background: $mengogradient !important;
        }
        &:nth-child(2),
        &:nth-child(3) {
          color: $white;
          padding: 15px 20px;
          text-align: center;
          border-radius: 8px;
          p {
            margin: 0;
            padding: 0;
            font-size: 14px;
            font-weight: normal;
          }
          span {
            font-size: 24px;
            font-weight: bold;
          }
        }
      }
    }
    tr {
      td {
        position: relative;
        &:first-child {
          color: $lightblack;
          padding: 10px 0;
          font-size: 14px;
          border-top: 1px solid rgba($lightblack, 0.1);
          i {
            color: $deyouk;
            padding-left: 10px;
          }
          min-width: 260px;
        }
        &:nth-child(2) {
          background: $lightgray;
          padding-top: 15px;
        }
        &:nth-child(3) {
          left: 15px;
          padding: 10px 0;
        }
        &:nth-child(2),
        &:nth-child(3) {
          // i {
          //   color: $grass;
          //   font-size: 20px;
          //   margin: 0 auto;
          //   text-align: center;
          //   width: 100%;
          //   min-width: 200px;
          // }

          color: $lightblack;
          padding: 10px 0;
          font-size: 14px;
          border-top: 1px solid rgba($lightblack, 0.1);
          i {
            color: $deyouk;
            padding-left: 10px;
          }
          min-width: 260px;
        }
      }
    }
  }
  button {
    color: $pink;
    border: none;
    background: transparent;
    font-weight: bold;
    font-size: 22px;
    position: absolute;
    right: 40px;
    display: inline-block;
    top: 0px;
    &:focus {
      outline: none;
    }
  }
}

.grid-container-inclusion {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  overflow-x: auto;
}
.grid-item-inclusion {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: left;
}
.grid-item-inclusion2 {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: right;
}
.edel-plan-table {
  border-top: 0 !important;
  border-bottom: 0 !important;

  .table-head {
    margin-bottom: 2rem;
  }
  tr:not(.table-head) {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    td {
      padding: 0.7rem !important;
      font-size: 15px !important;
      &:last-child {
        text-align: left !important;
      }
    }
  }
}

.edel-modal-scroll {
  overflow: hidden !important;
}
