.choicesbox {
  @include boxshow;
  // max-height: 50%;
  margin-top: 60px;
  margin-bottom: 100px;
  padding: 30px 20px;
  border-radius: 25px;
  box-shadow: 0px 3px 14px var(--aqua-1) !important;
  .close-add-member {
    color: $pink;
    position: absolute;
    right: 0;
    bottom: 60px;
    font-size: 20px;
  }
  .custom-switch {
    display: inline-block;
    margin: 0;
    cursor: pointer;
    position: relative;
    right: 0;

    .custom-control-input:checked ~ .custom-control-label::before {
      background-color: var(--aqua-9);
      border-color: var(--aqua-9);
    }

    .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
      border-color: var(--aqua-1);
      background-color: var(--aqua-1);
    }

    .custom-control-label {
      &::after {
        background-color: $white;
      }

      &::before {
        background-color: var(--aqua-1);
        border-color: var(--aqua-1);
      }
    }

    .custom-control-input:focus ~ .custom-control-label::before {
      box-shadow: none;
    }
  }

  .choiselistscroll {
    overflow-y: scroll;
    height: 410px;
    overflow-x: hidden;
    width: 100%;
    padding: 0 15px;
    // &::-webkit-scrollbar {
    //   width: 5px;
    // }
  }
  .choicestitle {
    border-bottom: 1px solid var(--gray-2);
    padding: 0px 0 10px;
    span {
      font-size: 20px;
      color: var(--aqua-9);
      font-weight: bold;
    }
    p {
      font-size: 12px;
      color: var(--aqua-1,0.5);
    }
    .preferredsum {
      color: var(--aqua-9);
      text-align: right;
      position: absolute;
      top: 20px;
      right: 15px;
      font-size: 14px;
    }
  }
}
.choiselist {
  border-bottom: 1px solid var(--gray-2);
  padding: 15px 0;
  .chiseprice {
    text-align: right;
    color: var(--aqua-9);
  }
  .custome-input {
    input[type="text"] {
      border: 1px solid var(--aqua-9);
      border-radius: 50px;
      padding-left: 10px;
      background-color: var(--aqua-2);
    }
    .MuiInput-underline:before,
    .MuiInput-underline:hover {
      border-bottom: none !important;
    }
  }
  .col-md-3, .col-md-1{
    color: var(--aqua-1);
  }
  
}
.choicecheck {
  padding-left: 20px;
  label {
    color: var(--aqua-1);
    font-size: 14px;
    font-weight: 500;
  }
  .custom-control-input:checked ~ .custom-control-label {
    color: $pink;
    &::before {
      color: $white;
      border-color: $pink;
      background-color: $pink;
    }
  }
  .custom-control-input:focus ~ .custom-control-label {
    &::before {
      box-shadow: none;
    }
  }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label {
    &::before {
      border-color: 2px solid var(--coral-4);
    }
  }
  .custom-control-label::before {
    border: 1.4px solid var(--coral-4);
    border-radius: .25rem;
  }
}
.pricedetails {
  text-align: right;
  span {
    font-size: 22px;
    font-weight: bold;
    color: var(--aqua-9);
  }
  p {
    font-size: 12px;
    color:var(--aqua-1,0.8);
    margin-bottom: 0;
  }
}

.choicefooter {
  padding: 10px;
  background: $white;
  box-shadow: 0px 3px 24px $deadsea;
  margin: 0 auto;
  width: 100%;
  text-align: left;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 1;
  button {
    border-radius: 50px;
    background: var(--aqua-9);
    color: $white;
    padding: 10px 30px;
    width: 100%;
  }
}

.custome-switch-text {
  p {
    color: var(--aqua-9);
    font-size: 14px;
    font-weight: 500;
    padding: 2px 7px 0 0px;
    left: 0;
    margin: 0;
  }
  i {
    font-size: 14px;
    border: none;
    padding: 0;
    background: $gray;
    color: $white;
    &:hover {
      // background: $gray;
      // color: $white;
    }
  }
}
.MuiFormLabel-root{
  color: var(--aqua-9) !important;
}
.topupqueckquotfooter{
  .container button{
    background-color: var(--aqua-9);
    color: white;
    padding: 8px 50px;
    border-radius: 50px;
    border: 1px solid var(--aqua-9) !important;
    &:hover{
      filter: brightness(80%);
    }
    &:focus{
      outline: none;
    }
  }
}
.choicecheck .custom-control-input:checked ~ .custom-control-label::before{
  border-color: var(--aqua-7) !important;
  background-color: var(--aqua-7) !important;
}
.choicecheck .custom-control-input:checked ~ .custom-control-label{
  color: var(--aqua-9) ;
}

.choicecheck .custom-control-input:focus:not(:checked)~.custom-control-label {
  &::before {
    border-color: var(--coral-4) !important;
  }
}

.addonspopup .choicesbox .choiselistscroll .choiselist .custome-input label{
  padding-left: 8px;
}

.addons-closebtn {
  position: initial !important;
}