.inputborder {
  .MuiInput-underline {
    border: 1px solid $deyouk;
    border-radius: 50px;
    padding: 4px 9px;
    height: auto;
    font-weight: normal;
    background-color: var(--aqua-2) !important;
    border-color: 1 px solid var(--aqua-9);
  }
  .MuiInput-underline:before,
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none !important;
  }
  [data-shrink="false"] {
    padding-left: 10px;
    padding-top: 5px;
  }
  [data-shrink="true"] {
    padding-left: 5px;
    margin-top: -5px;
    color: var(--aqua-9) !important;
  }
}
.inputborder1 {
  .MuiInput-underline {
    border: 1px solid $deyouk;
    border-radius: 50px;
    padding: 4px 9px;
    height: auto;
    font-weight: normal;
    background-color: var(--aqua-2) !important;
    border-color: 1px solid var(--aqua-9);
  }
  .MuiInput-underline:before,
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none !important;
  }
  [data-shrink="false"] {
    padding-left: 10px;
    padding-top: 5px;
  }
  [data-shrink="true"] {
    padding-left: 5px;
    margin-top: -5px;
    color: var(--aqua-9) !important;
  }
}


[role="presentation"] {
  label {
    padding-left: 10px;
    color: var(--aqua-9);
  }
}
.topcommnspace {
  margin-top: 20px !important;
}
.proposaldetailsmain {
  margin-top: 30px;
  .proposaldetailsbox {
    &:last-child {
      margin-bottom: 140px;
    }
  }
}
.proposaldetailsbox {
  @include boxshow;
  padding: 1px 30px 30px;
  margin-bottom: 100px;
  border-radius: 25px;

  .selectplanbtn .planrightbtn{
    button[disabled] {
      color: #666;
      background-color: #ccc;
      border-color: #ccc;
      filter: brightness(100%) !important;
    }
  }

  .contactborder{
    .inputborder{
      .custome-input-date{
        .MuiTextField-root{
          top: 3px;
        }
        input{
          padding-left: 6px;
          color: var(--aqua-9);
        }
        input.Mui-disabled{
          color: var(--gray-3);
        }
      }
    }
    .proposalnominee{
      .inputborder{
        .custome-input{
          textarea{
            padding-left: 11px;
          }
          #addressarea-label, #permanentAddress-label{
            top: -3px;
          }
        }
      }
    }
  }
  .discountquestion{
    p{
      color:var(--aqua-9);
    }
  }

  .addresstitle{
    span{
      color: var(--aqua-9);
    }
    input{
      accent-color: var(--aqua-9);
    }
    label{
      color: var(--aqua-9);
    }
  }

  .proposaltitle {
    border-bottom: 1px solid var(--gray-2);
    padding: 10px 0 10px;


    span {
      font-size: 20px;
      color: var(--aqua-9);
      font-weight: 600;
    }

    a {
      color: var(--aqua-9);
      text-align: right;
      float: right;
      text-decoration: none;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .proposalfrm {
    .custome-input .MuiTextField-root{
      input{
        padding-left: 10px;
      }
      .Mui-disabled{
        color: var(--gray-3);
      }
    }
    .custome-input-date .MuiTextField-root{
      top: 3px;
    }

    label {
      padding-left: 15px;
      padding-top: 15px;
      color: var(--aqua-9);
    }

    label.Mui-disabled {
      color: var(--gray-3) !important;
    }

    label.Mui-disabled.MuiFormLabel-filled {
      color: var(--aqua-9) !important;
    }

    input {
      padding: 10px 15px;
      font-weight: normal;
      height: auto;
      width: 100%;
      border-radius: 50px;
      color: var(--aqua-9);
    }
    input.Mui-disabled {
      color: var(--gray-3);
    }

    label + .MuiInput-formControl {
      margin-top: 28px;
      border: 1px solid var(--aqua-9);
      border-radius: 50px;
      background-color: var(--aqua-2) !important;
      color: var(--aqua-9);
    
    }


    .MuiInput-underline {
      border: none;
    }

    .MuiInput-underline:before,
    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: none !important;
    }

    .custome-select .MuiSelect-select.MuiSelect-select {
      padding: 10px 5px;
      border-radius: 50px;
    }

    .MuiSwitch-root{
      .MuiSwitch-switchBase{
        color: var(--aqua-1);
      }
      .MuiSwitch-switchBase.Mui-checked{
        color: var(--aqua-9);
      }
      .MuiSwitch-track{
        background-color: var(--aqua-9);
      }
    }
  }

  .proposalnominee {
    // margin-top: 20px;
    .custome-input {
      // .MuiInput-underline:hover:not(.Mui-disabled)::before {
      //   border-bottom: 0 !important;
      // }
      .MuiInputBase-multiline {
        padding: 0;
      }

      textarea {
        // border: 1px solid $deyouk;
        border-radius: 50px;
        padding: 10px 5px;
        font-weight: normal;
        height: auto;
      }

      > div {
        width: 100%;
      }

      label {
        padding-top: 15px;
        padding-left: 10px;
      }

      input {
        // border: 1px solid $deyouk;
        border-radius: 50px;
        padding: 8px 5px;
        font-weight: normal;
        height: auto;
        color: var(--aqua-9);
      }

      input.Mui-disabled {
        color: var(--gray-3);
      }

      label + .MuiInput-formControl {
        margin-top: 30px;
        border-radius: 50px;
        border: 1px solid var(--aqua-9);
      }

      .MuiInput-underline:before,
      .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom: none;
      }
      .custome-select {
        label + .MuiInput-formControl {
          margin-top: 12px;
        }
        .MuiInputBase-input {
          // padding: 10px 9px;
          padding-right: 20px; 
          background-color: var(--aqua-2); 
          border-radius: 50px; 
          background-color: var(--aqua-2); 
          color: var(--aqua-9);
        }
      }
    }

    .custome-input-date input{
      color: var(--aqua-9);
    }

    .otherdetailrad {
      margin-top: 36px;
    }

  }

  .custome-input {
    .MuiInput-underline {
      &::before {
        border-bottom: none;
      }
      &:hover {
        // border-bottom: none;
      }
    }

    label {
      line-height: 20px;
      line-height: initial;
      left: 0px;
      top: -1px;
    }

    input {
      // border: 1px solid $deyouk;
      border-radius: 50px;
      padding-left: 6px;
      font-weight: normal;
      background-color: var(--aqua-2) !important;
      height: auto;
    }
  }
  .proposalnominee .inputborder .custome-input-date .MuiTextField-root{
    top: 3px;
    input{
      padding-left: 6px;
    }
  }
}

.addressbox {
  margin-bottom: 140px;
  margin-top: 0;
}
.addresstitlecheckbox {
  label {
    color: $black;
    font-size: 14px;
    font-weight: 500;
  }

  .custom-control-input:checked ~ .custom-control-label {
    color: $pink;

    &::before {
      color: $white;
      border-color: $pink;
      background-color: $pink;
    }
  }

  .custom-control-input:focus ~ .custom-control-label {
    &::before {
      box-shadow: none;
    }
  }

  .custom-control-input:focus:not(:checked) ~ .custom-control-label {
    &::before {
      border-color: $pink;
    }
  }
}

.dicsountsbox {
  margin-bottom: 140px;
}

.proposaltitle {
  margin: 30px 0 10px;
  h2 {
    color: var(--aqua-9);
    font-weight: bold;
    font-size: 24px;
  }

  p {
    font-size: 14px;
    color: var(--aqua-1);
  }
}

.proposalaction {
  text-align: right;

  .active {
    span {
      background: var(--coral-4);
      border: 2px solid var(--coral-4);
      color: $white;
    }
  }

  a {
    width: 25%;
    display: inline-block;
    position: relative;

    span {
      border: 2px solid $gray;
      font-size: 14px;
      border-radius: 50%;
      text-align: center;
      color: var(--aqua-9);
      padding: 2px 0;
      display: inline-block;
      width: 30px;
      height: 30px;
      z-index: 1;
      position: relative;
      border: 2px solid var(--gray-2);

      &:hover {
        border: 2px solid var(--coral-4);
        text-decoration: none;
      }

      &:focus {
        background: var(--mustard-1);
        color: $white;
      }
    }

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      &:after,
      &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        border-bottom: 2px solid var(--coral-4);
        top: 14px;
        z-index: 0;
        width: 83px;
      }
    }
  }
}

.proposalfooter {
  @include fixedfooter;
  position: fixed;

  button,
  .button:focus {
    color: $white;
    background: var(--aqua-9);
    border-radius: 50px;
    padding: 12px 15px;
    border: none;
    text-align: center;
    text-transform: none;
    width: 100%;
    outline: none;
  }
}

.MuiFormLabel-root{
  z-index:1;

}

.form-check-label {
  margin-bottom: 0;
  color: var(--aqua-1);
}

#exampleRadios {
  accent-color: var(--aqua-9);
}

#ZunoMember {
  accent-color: var(--aqua-9);
}

#ExistingPolicy {
  accent-color: var(--aqua-9);
}

#ActivePolicy {
  accent-color: var(--aqua-9);
}

.MuiMenu-list {
  outline: 0;
  color:var(--aqua-9) !important;
  background-color : var(--aqua-2) !important;
  border-radius:25px !important;
}

.MuiMenu-paper{
  border-radius: 25px !important;
}

#ZunoMember{
  border:2px solid white;
  box-shadow:0 0 0 1px var(--coral-4);
  appearance:none;
  border-radius:50%;
  width:12px;
  height:12px;
  background-color:#fff;
  transition:all ease-in 0.2s;

}
#ZunoMember:checked{
  background-color:var(--aqua-7);
  box-shadow:0 0 0 1px var(--aqua-7);
}
#ExistingPolicy{
  border:2px solid white;
  box-shadow:0 0 0 1px var(--coral-4);
  appearance:none;
  border-radius:50%;
  width:12px;
  height:12px;
  background-color:#fff;
  transition:all ease-in 0.2s;

}
#ExistingPolicy:checked{
  background-color:var(--aqua-7);
  box-shadow:0 0 0 1px var(--aqua-7);
}
#ActivePolicy{
  border:2px solid white;
  box-shadow:0 0 0 1px var(--coral-4);
  appearance:none;
  border-radius:50%;
  width:12px;
  height:12px;
  background-color:#fff;
  transition:all ease-in 0.2s;

}
#ActivePolicy:checked{
  background-color:var(--aqua-7);
  box-shadow:0 0 0 1px var(--aqua-7);
}

.proposaldetailsbox .proposalnominee .inputborder .custome-input label {
  padding-top: 20px;
}
.MuiFormHelperText-root.Mui-error{
  color: black !important;
}

.nominee-details-box {
  margin-bottom: 40px !important;
}