.quickquotetitle {	
  padding: 3rem 0 0;	
  font-weight: bold;	
  span {	
    font-size: 18px;	
    display: inline-block;	
    color: var(--aqua-9) !important;	
    &:first-child {	
      text-align: left;	
      color: black;	
    }	
    &:last-child {	
      text-align: right;	
      color: $mengo;	
      float: right;	
    }	
  }	
}	
.quickquotemanin {	
  @include boxshow;	
  margin-bottom: 150px;	
  border-radius:25px !important;	
  box-shadow: 0px 3px 14px var(--aqua-1) !important;	
  .quickquotheader {	
    border-bottom: 1px solid var(--gray-2);	
    padding-bottom: 20px;	
    .insuredhedtitle {	
      font-size: 20px;	
      font-weight: bold;	
      color: var(--aqua-9);	
    }	
    .insuredright {	
      text-align: right;	
      span {	
        color: $gray;	
        font-size: 14px;	
        @include tooltipicongry;	
        i {	
          padding: 0;	
          font-size: 16px;	
        }	
      }
      .active{
        color: var(--aqua-9) !important;
      }
      // b {	
      //   color: $lightblack;	
      //   font-size: 14px;	
      //   @include tooltipicongry;	
      //   i {	
      //     font-weight: normal;	
      //     padding: 0;	
      //     font-size: 16px;	
      //   }	
      // }	
      .custom-switch {	
        display: inline-block;	
        margin: 0 10px 0 20px;	
        // cursor: pointer;	
        .custom-control-input:checked ~ .custom-control-label::before {	
          background-color: var(--aqua-9) !important;	
          border-color: var(--aqua-9) !important;	
        }	
        .custom-control-input:focus:not(:checked)	
          ~ .custom-control-label::before {	
          border-color: var(--aqua-1) !important;	
          background-color: var(--aqua-1) !important;	
        }	
        .custom-control-label {	
          &::after {	
            background-color: $white;	
            cursor: pointer;	
          }	
          &::before {	
            background-color: var(--aqua-1) !important;	
            border-color: var(--aqua-1) !important;	
          }	
        }	
        .custom-control-input:focus ~ .custom-control-label::before {	
          box-shadow: none;	
        }	
      }	
    }	
  }	
  .insureddetails {	
    .insuredtitle {	
      color: $lightblack;	
      font-size: 15px;	
      font-weight: bold;	
      text-align: left;	
      padding-top: 0px;	
      padding-bottom: 0px;	
      span {	
        &:last-child {	
          font-size: 14px;	
          text-align: right;	
          color: $carbon;	
          float: right;	
        }	
      }	
    }	
    .memberbox {	
      margin-bottom: 20px;	
      @include selectmember;	
    }	
    .husband {	
      label {	
        background-image: url(../../../Images/husband.png);	
      }	
    }	
    .spouse {	
      label {	
        background-image: url(../../../Images/girls.png);	
      }	
    }	
    .kids {	
      position: relative;	
      label {	
        background-image: url(../../../Images/kids.png);	
      }	
    }	
    .couterkids {	
      position: absolute;	
      right: 8px;	
      top: 16px;	
      span {	
        border-radius: 5px;	
        color: $blue;	
        font-size: 16px;	
        border: 1px solid;	
        padding: 0px 7px;	
        font-weight: bold;	
        &:nth-child(2) {	
          color: $lightblack;	
          border: none;	
        }	
      }	
    }	
    .custome-input-date {	
      border: none;	
      border-radius: 5px;	
      top: -8px;	
      padding: 5px 5px 0;	
      label {	
        color: $lightblack;	
        font-weight: bold;	
        top: -2px !important;	
        // padding-left: 15px;	
      }	
      label + .MuiInput-formControl {	
        background: $white;	
      }	
      .MuiFormLabel-root.Mui-focused {	
        top: 0px;	
      }	
      .MuiInput-underline {	
        &::before {	
          border-bottom: none;	
        }	
        input {	
          font-weight: bold;	
        }	
      }	
      .MuiTextField-root .MuiOutlinedInput-adornedEnd{	
        border-radius: 50px;	
        background-color: var(--aqua-2);
        height: 50px;
        input{
          color: var(--aqua-9);
        }
      }
      .MuiTextField-root{
        top: 5px;
      }
      .MuiFormHelperText-root.Mui-error{
        color: black;
      }
      .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
        border-color: var(--aqua-9);
      }
    }	
    .rangeslider {	
      input {	
        width: 100%;	
        background: $blue !important;	
      }	
      .range-field {	
        span {	
          font-size: 12px;	
          color: $lightblack;	
          float: left;	
          &:last-child {	
            text-align: right;	
            float: right;	
          }	
        }	
      }	
    }	
  }	
  .floaterdetails {	
    margin: 30px 0;	
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {	
      border: 1px solid black;	
    }
    .quickquoteselect {
      select {
        color: var(--aqua-9);
        border: 1px solid $deyouk;
        border-radius: 50px !important;
        background-color: var(--aqua-2) !important;
        padding: 15px 5px;
        height: auto;
        font-weight: bold;

        option {
          border: none;
        }
      }

      .form-control:focus {
        border: 1px solid var(--aqua-9);
        box-shadow: none;
      }
    }

    .floaterrangetitle {
      color: $carbon;
      font-size: 12px;
      font-weight: bold;
      text-align: left;
      color: var(--aqua-1);

      span {
        &:last-child {
          font-size: 14px;
          text-align: right;
          float: right;
        }
      }
    }

    .floaterrangeslider {
      input {
        width: 100%;
        background: $blue !important;
      }

      .range-field {
        line-height: 15px;

        span {
          font-size: 12px;
          color: $lightblack;
          float: left;

          &:last-child {
            text-align: right;
            float: right;
          }
        }
      }
      .custome-select{
        .MuiSelect-select.MuiSelect-select{
          color: var(--aqua-9) !important;
        }

        .MuiFormControl-root .MuiInputBase-root.Mui-focused .MuiSelect-select{
          background-color: white;
        }
      }
    }
    .floaterdate {
      border: none;
      border-radius: 5px;
      padding: 7px 5px 0;

      .MuiFormLabel-root.Mui-focused {
        top: 0px;
      }

      .custome-input-date label + .MuiInput-formControl {
        padding-left: 0px;
        background: $white;
      }

      label + .MuiInput-formControl {
        margin-top: 14px;
        padding-left: 0;
      }

      label {
        color: $lightblack;
        font-weight: bold;
        position: absolute;
        // top: -8px;
        top: -2px !important;
        padding-left: 0px;
      }

      .MuiInput-underline {
        &::before {
          border-bottom: none;
        }

        input {
          font-weight: bold;
        }
      }
      .MuiOutlinedInput-adornedEnd{
        border-radius: 50px;
        background-color: var(--aqua-2);
        height: 50px;
        input{
          color: var(--aqua-9);
        }
      }
      .MuiTextField-root{
        position: relative;
        top: -4px;
      }
      .custome-input-date{
        .MuiFormHelperText-root.Mui-error{
          color: black;
        }
        .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
          border-color: var(--aqua-9);
        }
      }
    }

    .floatercheckbox {
      padding-top: 15px;

      label {
        color: $seblack;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 20px 0;
      }

      .custom-control-label::after,
      .custom-control-label::before {
        left: 0;
        border: 2px solid var(--coral-4);
      }

      .custom-control-input:checked ~ .custom-control-label {
        color: $blue;

        &::before {
          color: $white;
          border-color: $pink;
          background-color: $pink;
        }
      }

      .custom-control-input:focus ~ .custom-control-label {
        &::before {
          box-shadow: none;
        }
      }

      .custom-control-input:focus:not(:checked) ~ .custom-control-label {
        &::before {
          border-color: var(--coral-4) !important;
        }
      }
    }
    .col-md-1 {
      .close-add-member {
        left: 0;
      }
    }
    .close-add-member {
      color: var(--coral-4);
      position: absolute;
      right: -15px;
      top: 8px;
    }

    .close-btn-padding {
      padding: 0px;
    }
    
    .invalid-feedback{
      color: var(--aqua-9) !important;
    }
  }

  .addmorbtn {
    button {
      color: var(--aqua-9);
      border: 1px solid var(--aqua-9);
      border-radius: 50px !important;
      background: $white;
      width: 100%;
      text-transform: none;
      outline: none;

      
      &:hover{
        background: var(--aqua-9);
        color: white;
      }
    }
  }
  .policyTypeBtn{
    button{
      width: 55% !important;
    }
  }
}

.queckquotfooter {
  @include fixedfooter;
  position: fixed;
  padding: 10px;
  border-radius: 0px;
  button{
    color: $white;
    border-radius: 50px !important;
    background-color: var(--aqua-9);
    text-transform: none !important;
    border: 1px solid var(--aqua-9);
    text-align: center;
    text-transform: none;
    width: 100%;
    font-weight: bold;
    outline: none;
   
    font-size: 14px;

    &:hover {
      background: var(--aqua-9);
      color: $white;
      filter: brightness(80%);
    }
  }
  .footerbtn {
    float: right;
    span {
      font-weight: bold;
      color:var(--aqua-9);
    }
  }

  .skipbtn {
    button {
      border: 1px solid $pink;
      background: transparent;
      color: $pink;
      font-size: 14px;
      &:hover {
        background: $pink;
        color: $white;
      }
    }
  }
}
.MuiMenu-list {
  outline: 0;
  color:var(--aqua-9) !important;
  background-color : var(--aqua-2) !important;
  border-radius:25px !important;
}
.MuiMenu-paper{
  border-radius: 25px !important;
  .MuiMenu-list {
    .MuiMenuItem-root.MuiMenuItem-gutters{
      font-size: 15px !important;
    }
  }
}
input[type=checkbox] {
  accent-color: var(--aqua-9);
}
.floatercheckbox .custom-control-input:checked ~ .custom-control-label::before{
  border-color: var(--aqua-7) !important;
  background-color: var(--aqua-7) !important;
}
.floatercheckbox .custom-control-input:checked ~ .custom-control-label{
  color: var(--aqua-9) ;
}
.custom-checkbox .custom-control-label::before{
  border: 1.4px solid var(--coral-4);
}

.qq-select {
  .custome-select {
    .MuiSelect-select {
      padding-bottom: 16px;
      padding-top: 16px;
      background-color: var(--aqua-2);
      border-radius: 50px;
      font-weight: bold;
      font-size: 16px;
    }
    fieldset {
      border-radius: 50px;
    }
  }
}
