.abha-details-card {
    border-radius: 25px !important;
    box-shadow: 0px 3px 14px var(--aqua-1) !important;

    .card-title {
        font-size: large !important;
        font-weight: bold !important;
    }

    .redirection-link {
        font-size: small !important;
        cursor: pointer !important;
    }

    .MuiDivider-root {
        margin-top: 8px !important;
        margin-bottom: 16px !important;
    }

    .abha-number-field {
        fieldset, input {
            border-radius: 50px !important;
        }

        input {
            background-color: var(--aqua-2) !important;
        }

        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: var(--aqua-9) !important;
        }
    }

    .disclaimer-box {
        margin-top: 32px !important;
        
        .disclaimer-content {
            font-size: small !important;
        }
    }

    .error-msg {
        font-size: 80% !important;
        color: black !important;
    }

    .MuiCheckbox-root {
        color: var(--coral-4) !important;
    }

    .MuiCheckbox-root.Mui-checked {
        color: var(--aqua-7) !important;
    }
}

.abha-card {
    margin-bottom: 200px !important;
}

.renewal-abha-card {
    margin-top: 10px !important;
}