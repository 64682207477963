.thankyoubox {
  padding: 20px;
  background: $white;
  box-shadow: 0px 3px 14px var(--aqua-1);
  border-radius: 10px;
  margin-top: 60px;
  margin-bottom: 20px;

  img {
    // min-width: 400px;
    // margin-left: -105px;
    // margin-top: -125px;
    // border-radius: 10px;

  }

  h2 {
    color: var(--aqua-9);
    border-bottom: 1px solid var(--gray-2);
    font-size: 18px;
    padding: 10px 0 20px;
    margin-bottom: 50px;
  }

  ul {
    list-style: none;
    padding: 0;
    color: var(--aqua-9);
    font-weight: bold;
    font-size: 14px;
  }

  table {
    margin: 30px 0;

    tr {
      td {
        font-size: 14px;
        color: var(--aqua-9);
        padding: 10px 0;

        &:last-child {
          color: var(--aqua-1);
          font-weight: bold;
          padding-left: 10px;
        }
      }
    }
  }

  .Proceedbtn {
    border: none;
    background: var(--aqua-9);
    color: $white;
    padding: 10px 10px;
    outline: none;
    margin-top: 15px !important;
    border-radius: 50px;
  }
}

.proposermainbox {
  min-height: 400px;
  vertical-align: middle;
  padding: 20px 50px;
  background: $white;
  box-shadow: 0px 3px 14px var(--aqua-1);
  border-radius: 25px;
  margin-top: 60px;
  padding-top: 120px;

  .proposerbox {
    div{
      color: var(--aqua-1);
    }
    h2{
      color: var(--aqua-9);
    }
    b {
      font-size: 20px;
      color: var(--aqua-9);
      display: inherit;
      line-height: initial;
    }

    p {
      font-size: 16px;
      margin-top: 0.5rem;
      color: var(--aqua-1);
    }

    button {
      font-size: 14px;
      padding: 10px 20px;
      border: none;
      border-radius: 50px;
      font-weight: bold;
      background: var(--aqua-9);
      color: $white;
      outline: none;
      margin-top: 15px !important;
      &:hover{
        filter: brightness(80%);
      }
    }
  }
  
}

.nstpbox {
  min-height: 400px;
  vertical-align: middle;
  padding: 20px 50px;
  background: $white;
  box-shadow: 0px 3px 14px var(--aqua-1);
  border-radius: 25px;
  margin-top: 40px;
  padding-top: 60px;

  .resonofnstp{
    color: var(--aqua-9);
  }

  .nstpdetails {
    span {
      font-size: 14px;
      color: var(--aqua-1);
    }
    

    p {
      font-size: 18px;
      font-weight: normal;
      margin: 0;
      color: var(--aqua-1);
    }
    
  }

  .customercare {
    color: $pink;
    padding-top: 30px;

    span {
      font-size: 14px;
    }

    p {
      font-size: 24px;
      font-weight: bold;
    }

    button {
      font-size: 14px;
      padding: 10px 20px;
      border: none;
      background: $pink;
      color: $white;
      outline: none;
      margin-top: 15px !important;
      margin-bottom: 50px;
      &:hover{
        filter: brightness(80%);
      }
    }
  }
}
.quickcontact{
  color:var(--aqua-1);
}

.onesteplogin{
  color:var(--aqua-1);
}

.hospital{
  color:var(--aqua-1);
}

.gotohomepage{
  margin-left: 475px;
}

@media (max-width: 767.98px) {
  .nstp-thankyou-box {
    margin-top: 20vh;
  }
}