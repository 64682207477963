.congratulationdbox {
  @include boxshow;
  margin-top: 60px;
  box-shadow: 0px 3px 14px var(--aqua-1) !important;
  border-radius:25px !important;
  .congratulationheader {
    text-align: center;
    border-bottom: 1px solid var(--gray-2) !important;
    margin: 20px 0;
    span {
      font-size: 24px;
      font-weight: bold;
      color: var(--aqua-9) !important;
      background: url(../../../Images/Crackerbg.svg);
      height: 140px;
      width: 250px;
      background-position: bottom;
      padding: 40px 0;
    }
    p {
      color: var(-gray-1) !important ;
      font-size: 18px;
      display: inline-block;
      padding-left: 5px;
      margin-bottom: 40px;
    }
  }
  img {
    padding-top: 30px;
  }
  .policydetails {
    h4 {
      font-size: 22px;
      padding-bottom: 20px;
    }

    table {
      width: 100%;
      margin-bottom: 50px;
      tr {
        padding: 10px 0;
        display: inline-block;
        width: 100%;
        td {
          font-size: 12px;
          font-weight: bold;
          &:first-child {
            color: var(--aqua-1) !important;
            opacity: 0.5;
            min-width: 105px;
          }
          &:last-child {
            text-align: left;
            color: var(--aqua-1) !important;
            sub {
              opacity: 0.5;
              padding-left: 5px;
              color: var(--aqua-1) !important;
            }
          }
        }
      }
    }
  }
}
.downloadletter {
  text-align: center;

  button{
    border-radius: 50px;
    background-color: var(--aqua-9);
    border:1px solid var(--aqua-9) !important;
    text-transform: none !important;
    margin-top: 15px;
    &:hover{
      filter: brightness(80%);
    }
  }

  p {
    color: $gray;
    font-size: 12px;
  }
}
