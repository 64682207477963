@media only screen and (max-width: 767px) {
  .lastquestionbox {
    margin-top: 90px !important;
    box-shadow: none !important;
    padding: 30px 0 !important;

    .addquestion {
      padding: 10px 0 !important;
    }
  }

  .header-top-spacing {
    margin-top: 100px !important;
  }

  .selfdetails {
    margin: 10px 0;

    .custome-input,
    .custome-input-date,
    .custome-select {
      margin-top: 0px;
    }
  }

  .selectplanbtn {
    button {
      padding: 8px 10px;
    }
  }

  .selecteplantopbox {
    margin-top: 90px;

    .pricelist {
      margin: 10px 0;
      text-align: left;
    }
  }

  .proposaltitle {
    margin: 100px 0 10px !important;
  }

  .proposaldetailsbox {
    margin-top: 10px;
    margin-bottom: 100px !important;

    .proposaltitle {
      margin: 0px 0 10px !important;
    }

    .proposalnominee {
      margin-top: 0px;

      .otherdetailrad {
        margin-top: 0px !important;
      }
    }
  }

  .proposalaction {
    width: 300px;

    a:nth-child(2) {
      &::before {
        width: 72% !important;
      }

      &::after {
        border-bottom: none !important;
      }
    }

    a:nth-child(3) {
      &::before {
        width: 72% !important;
      }

      &::after {
        border-bottom: none !important;
      }
    }
  }

  .headermain {
    position: fixed;
  }

  .tolfreenumber {
    display: none;
  }

  .membertopselect {
    margin: 10px 0;
  }

  .Membertitlebox {
    .membertopselect {
      .custome-select {
        padding: 10px 0px;
      }
    }
  }

  .MemberDetailsbox {
    box-shadow: none;
    padding: 15px;

    .Memberlistingbox {
      .custome-input-date {
        width: 100%;
      }
    }

    .addmembertitle {
      display: none;
    }

    .addmemberbtn {
      text-align: center;

      button {
        width: 80%;
      }
    }
  }

  .mobilefix {
    position: fixed !important;
    bottom: 0;
    padding-right: 0 !important;
    padding-left: 0 !important;
    width: 100% !important;
  }

  .quotebtn,
  .memberbtn {
    margin-bottom: 0;
    border-radius: 0;
    margin-left: 0;
    width: 50%;
    font-weight: bold;
  }

  .seletedfooter {
    .Proceedbtn {
      padding: 8px 12px;
      border-radius: 50px;
      background-color: var(--aqua-9);
      color: white;
    }
   
  }

  .contactdetails {
    margin-top: 0px;

    .custome-input,
    .custome-input-date,
    .custome-select {
      padding: 0 !important;
    }
  }

  .contactotherdet {
    .contactyesnobtn {
      margin-top: 25px;
      padding-bottom: 0px;
      padding-top: 10px;
    }

    h3 {
      padding-top: 10px;
      padding-bottom: 0;
    }
  }

  .modalfrm {
    .modalbtn {
      button {
        padding: 8px 6px !important;
      }
    }
  }

  .queckquotfooter {
    button {
      padding: 12px 8px !important;
    }
  }

  .addtoanother {
    .undefined {
      margin-top: 20px;
    }
  }
}

.PreMedicalpopup {
  margin-bottom: 50px !important;
}
.spouse{
  border:2px solid white;
  box-shadow:0 0 0 1px var(--coral-4);
  appearance:none;
  border-radius:50%;
  width:12px;
  height:12px;
  background-color:#fff;
  transition:all ease-in 0.2s;

}
.spouse:checked{
  background-color:var(--aqua-7);
  box-shadow:0 0 0 1px var(--aqua-7);
}
.othernominee{
  border:2px solid white;
  box-shadow:0 0 0 1px var(--coral-4);
  appearance:none;
  border-radius:50%;
  width:12px;
  height:12px;
  background-color:#fff;
  transition:all ease-in 0.2s;

}
.othernominee:checked{
  background-color:var(--aqua-7);
  box-shadow:0 0 0 1px var(--aqua-7);
}
.seletedfooter .Proceedbtn{
  // padding: 8px 12px;
      border-radius: 50px;
      background-color: var(--aqua-9);
      color: white;

}