@font-face {
  font-family: "Roboto", sans-serif;
  src: local("Roboto"), url("../fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Bold", sans-serif;
  src: local("Roboto-Bold"), url("../fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Light", sans-serif;
  src: local("Roboto-Light"),
    url("../fonts/Roboto-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Medium", sans-serif;
  src: local("Roboto-Medium"),
    url("../fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

.max-width-100 {
  max-width: 100% !important;
}

body {
  font-family: "Roboto", sans-serif !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.MuiSnackbar-root {
  z-index: 99999 !important;
}
