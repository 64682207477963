.topuppolicysubheader {
  background: $white;
  box-shadow: 0px 3px 14px rgb(36, 180, 179);
  padding: 10px 0;
  .topuppolicybox {
    // background: $lightgray;
    // border: 1px solid $gray;
    border-radius: 5px;
    width: 100%;
    padding: 0 5px 5px;
    label {
      font-size: 14px;
      color: green;
      width: 100%;
      margin-bottom: 0;
    }
    span {
      color: $seblack;
      font-size: 14px;
      font-weight: bold;
      width: 100%;
    }
    &:hover {
      label,
      input {
        color: var(--aqua-1);
      }
    }
  }
}
.topuppolicyheader {
  .insuredright {
    // text-align: right;
    span {
      color: #989898;
      font-size: 14px;
      @include tooltipicongry;

      &.active {
        position: relative;
        left: -4px;
        color: var(--aqua-9);
      }
      i {
        font-size: 16px;
        border: none;
        padding: 0;
        background: $gray;
        color: $white;
        &:hover {
          // background: $gray;
          // color: $white;
        }
      }
    }
    b {
      color: $lightblack;
      font-size: 14px;
      @include tooltipicongry;
      i {
        font-weight: normal;
        font-size: 16px;
        padding: 0;
        border: none;
        &:hover {
          background: $gray;
        }
      }
    }
    .custom-switch {
      display: inline-block;
      margin: 0 3px 0 16px;
      padding-left: 0;
      .custom-control-input:checked ~ .custom-control-label::before {
        background-color: var(--aqua-9);
        border-color: var(--aqua-9);
      }
      .custom-control-input:focus:not(:checked)
        ~ .custom-control-label::before {
        border-color: var(--aqua-1);
        background-color: var(--aqua-1);
      }
      .custom-control-label {
        padding-left: 0 !important;
        &::after {
          background-color: $white;
          cursor: pointer;
        }
        &::before {
          background-color:var(--aqua-1);
          border-color: var(--aqua-1);
          cursor: pointer;
        }
      }
      .custom-control-input:focus ~ .custom-control-label::before {
        box-shadow: none;
      }
    }
  }
  // .insuredright {
  //   text-align: right;
  //   margin-top: 15px;
  //   span {
  //     color: $gray;
  //     font-size: 14px;
  //     @include tooltipicongry;
  //   }
  //   b {
  //     color: $lightblack;
  //     font-size: 14px;
  //     @include tooltipicongry;
  //     i {
  //       font-weight: normal;
  //     }
  //   }
  //   .custom-switch {
  //     display: inline-block;
  //     margin: 0 10px 0 20px;
  //     cursor: pointer;
  //     .custom-control-input:checked ~ .custom-control-label::before {
  //       background-color: $pink;
  //       border-color: $pink;
  //     }
  //     .custom-control-input:focus:not(:checked)
  //       ~ .custom-control-label::before {
  //       border-color: $blue;
  //       background-color: $blue;
  //     }
  //     .custom-control-label {
  //       &::after {
  //         background-color: $white;
  //       }
  //       &::before {
  //         background-color: $blue;
  //         border-color: $blue;
  //       }
  //     }
  //     .custom-control-input:focus ~ .custom-control-label::before {
  //       box-shadow: none;
  //     }
  //   }
  // }
}

.topuppolicydetailsmain {
  @include boxshow;
  margin-bottom: 150px;
  .topuppolicyplanlist {
    padding-bottom: 30px;
    h3 {
      font-weight: bold;
      color: rgb(0, 70, 79);
      font-size: 20px;
    }
    p {
      margin-bottom: 0;
      color: rgba(rgb(0, 70, 79), 0.7);
      font-size: 12px;
    }
    span {
      color: $blue;
      font-size: 14px;
      font-weight: bold;
    }
    .Suminsured {
      span {
        font-size: 22px;
        font-weight: bold;
        color: $lightblack;
      }
      p {
        margin-bottom: 0;
      }
    }
  }
  .topupgoldplansingal {
    background: url("../../../Images/GoldplanSinbg.png");
    background-size: cover;
  }
  .topupblueplan {
    background: $blue;
    background-size: cover;
  }
  .topupgoldplansingal,
  .topupblueplan {
    padding: 18px 15px;
    border-radius: 10px;
    .topupgoldpalntitle {
      font-size: 20px;
      color: $white;
      padding-bottom: 10px;
    }
    .topupgoldbtn {
      button {
        border: 1px solid $white;
        border-radius: 5px;
        background: rgba($white, 0.5);
        color: $white;
        width: 100%;
        padding: 10px 15px;
        margin-top: 15px;
        text-transform: uppercase;
        outline: none;
      }
    }
    .topupgoldpalnprice {
      color: $white;
      span {
        font-size: 22px;
        font-weight: bold;
      }
      sub {
        font-size: 10px;
        padding-left: 5px;
      }
    }
    table {
      padding: 10px 0;
      // display: inline-block;
      width: 100%;
      border-bottom: 1px solid rgba($lightwhite, 0.5);
      border-top: 1px solid rgba($lightwhite, 0.5);
      tr {
        &:first-child {
          padding: 10px 0;
        }
        td {
          color: $white;
          font-size: 12px;
          padding: 5px 0;
          &:last-child {
            text-align: right;
          }
        }
      }
    }
  }

  .topupsilverplansingal {
    background: url("../../../Images/sliverplanbg.png");
    background-size: cover;
    padding: 18px 15px;
    border-radius: 10px;
    .topupsilverpalntitle {
      font-size: 20px;
      // border-bottom: 1px solid rgba($lightwhite, 0.5);
      color: $white;
      padding-bottom: 30px;
      padding-top: 10px;
      .topupsilverpalnprice {
        // top: 10px;
        // position: absolute;
        // right: 30px;
        float: right;
        span {
          text-align: right;
          font-size: 22px;
          font-weight: bold;
          display: block;
        }
        p {
          font-size: 12px;
          text-align: right;
        }
      }
    }

    table {
      padding: 10px 0;
      width: 100%;
      border-bottom: 1px solid rgba($lightwhite, 0.5);
      border-top: 1px solid rgba($lightwhite, 0.5);
      tr {
        &:first-child {
          padding: 10px 0;
        }
        td {
          color: $white;
          font-size: 12px;
          padding: 5px 0;
          &:last-child {
            text-align: right;
          }
        }
      }
    }
  }

  .topupcompareplanslink {
    color: $pink;
    text-align: right;
    font-size: 12px;
    text-transform: uppercase;
    padding: 30px 0 0;
    font-weight: bold;
    cursor: pointer;
    i {
      font-size: 20px;
      padding: 10px 6px;
      vertical-align: initial;
    }
    img {
      width: 30px;
      vertical-align: top;
      padding-right: 10px;
      padding-top: 2px;
    }
    button {
      color: $pink;
      background: transparent;
      border: none;
      border: none;
      padding: 0;
      font-weight: 600;
      outline: none;
      font-size: 18px;
      font-weight: 600;
    }
  }
}
.topuppolicytitlecheckbox {
  @include policycheckbox;
}

.topuptermdropdown {
  position: relative;
  bottom: 7px;
  .topuptermlist {
    // display: none;
    position: absolute;
    outline: none;
    width: 100%;
    z-index: 1;
    border-radius: 5px;
    background: $white;
    box-shadow: 0 0px 1px;
    border: 1px solid $gray;
    padding: 5px;
    a,
    option {
      color: $seblack;
      padding: 6px 6px;
      text-decoration: none !important;
      display: block;
      font-size: 14px;
      font-weight: bold;
      span,
      p {
        color: $pink !important;
        font-weight: bold;
        background: rgba($pink, 0.2);
        border-radius: 30px;
        padding: 4px 6px;
        float: right;
        font-size: 10px !important;
        margin-bottom: 0;
      }
      p {
        background: transparent;
        color: $lightblack !important;
        font-size: 12px !important;
      }
      &::hover {
        background-color: #ddd;
        text-decoration: none;
      }
    }
  }

  &:hover .termlist {
    display: block;
  }
}

.topuppolicyadonbox {
  border: 1px solid rgba($dugong, 0.5);
  padding: 10px 20px;
  border-radius: 5px;
  .topupadonheder {
    border-bottom: 1px solid rgba($dugong, 0.5);
    padding-bottom: 10px;
    span {
      color: $lightblack;
      font-weight: bold;
      font-size: 18px;
      &:first-child {
        text-align: left;
      }
      &:last-child {
        text-align: right;
        float: right;
      }
    }
  }
  .topupaddonlist {
    width: 100%;
    tr {
      td {
        .addonchecklist {
          @include policycheckbox;
        }
        font-weight: bold;
        color: $blue;
        font-size: 14px;
        padding: 10px 0;
        &:last-child {
          text-align: right;
        }
        i {
          font-size: 18px;
          padding-left: 7px;
        }
      }
    }
  }
}
.topupsuperplan {
  background: url("../../../Images/Goldplanbg.png");
  background-size: cover;
}
.topuppolicygplanbox {
  .topuppalnprice {
    text-align: right;
    position: absolute;
    top: 10px;
    right: 26px;
    span {
      font-size: 30px;
      font-weight: bold;
    }
    p {
      margin: 0;
      font-size: 10px;
    }
  }
  padding: 10px;
  border-radius: 10px;
  .topuppalntitle {
    font-size: 20px;
    border-bottom: 1px solid rgba($lightwhite, 0.5);
    color: $white;
    padding-bottom: 30px;
    padding-top: 10px;
  }
  table {
    padding: 10px 0;
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid rgba($lightwhite, 0.5);
    tr {
      width: 100%;
      display: inline-block;
      td {
        color: $white;
        font-size: 12px;
        padding: 10px 0;
        float: left;
        width: 50%;
        &:last-child {
          text-align: right;
        }
      }
    }
  }
  .superplanprice {
    color: $white;
    padding: 20px 0 30px;
    font-weight: bold;
    strong {
      font-size: 18px;
      font-weight: normal;
    }
    span {
      font-size: 20px;
      text-align: right;
      position: absolute;
      right: 25px;
      p {
        font-size: 10px;
      }
    }
  }
  button {
    background: rgba($white, 0.5);
    border: 1px solid $white;
    border-radius: 5px;
    width: 100%;
    color: $white;
    margin-bottom: 10px;
    text-transform: uppercase;
    outline: none;
  }
}
.healthtopup {
  padding-top: 60px;
  span {
    font-size: 16px;
    font-weight: bold;
    color: $lightblack;
  }
  p {
    font-size: 14px;
    color: rgba($lightblack, 0.5);
    border-bottom: 1px solid $skies;
    padding-bottom: 10px;
  }
  .topupcompareplanslink {
    text-align: left;
    padding: 10px 0 0;
  }
}

.notisplan {
  padding: 20px 0 0;
  font-size: 14px;
  cursor: pointer;
  p {
    display: inline-block;
    padding-left: 5px;
  }
  i {
    color: $pink;
    padding-right: 10px;
  }
}
.topuppolicyfooter {
  @include fixedfooter;
  position: fixed;
  .payviw {
    button {
      color: $white;
      background: $pink;
      border: none;
      outline: none;
    }
  }
  .topuppolicywordbtn {
    img {
      position: absolute;
      top: 7px;
      left: 22px;
      width: 25px;
    }
  }
  .topuppolicybtn,
  .topuppolicywordbtn {
    button {
      color: $pink;
      border: 1px solid $pink;
      background: $white;
      outline: none;
      &:hover {
        background: $pink;
        color: $white;
      }
    }
  }
  button,
  .button:focus {
    border-radius: 5px;
    padding: 12px 8px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    outline: none;
    font-size: 14px;
  }
}
.topupgoldbtn {
  button {
    border: 1px solid var(--aqua-9);
    border-radius: 50px;
    background: white;
    color: rgb(0, 70, 79);
    width: 100%;
    padding: 10px 15px;
    margin-top: 15px;
    text-transform: uppercase;
    outline: none;
    font-size: 18px;
    text-transform: none;
    &:hover
    {
      background:var(--aqua-9);
      color: white;

    }
    &:focus {
      outline: none;
      box-shadow: inset 0 0 50px rgb(36, 180, 179);
    }
  }
}

