.custom-control-input:checked~.custom-control-label {
  color: var(--aqua-1) !important;

  &::before {
    color: $white;
    border-color: var(--aqua-7) !important;
    background-color: var(--aqua-7) !important;
  }
}

.custom-control-input:focus~.custom-control-label {
  &::before {
    box-shadow: none;
  }
}

.custom-control-input:focus:not(:checked)~.custom-control-label {
  &::before {
    border-color: var(--coral-4);
  }
}
