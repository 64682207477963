.custome-input {
  position: relative;

  .input {
    border: 0;
    padding: 4px 0;
    border-bottom: 1px solid #ccc;
    background-color: transparent;
    box-sizing: border-box;
    

      
    &:focus {
      outline: none;
    }
  }

  .input ~ label {
    position: absolute;
    visibility: hidden;
    left: 0;
    top: 25px;
    // transition: 0.3s;
    // z-index: -1;
  }

  .input:focus::-webkit-input-placeholder {
    color: transparent;
  }
  .input:focus ~ label,
  .has-content.input ~ label {
    visibility: visible;
    top: -1px;
    transition: 0.3s;
  }
}
.MuiInput-underline:after{
  border-bottom: none !important;
}
