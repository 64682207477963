@mixin boxshow {
  padding: 30px;
  background: $white;
  box-shadow: 0px 3px 14px var(--aqua-1);
  border-radius: 5px;
  margin-top: 40px;
  margin-bottom: 40px;
}
@mixin fixedfooter {
  padding: 20px;
  background: $white;
  box-shadow: 0px 3px 14px var(--aqua-1);
  border-radius: 5px;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  position: relative;
  width: 100%;
  bottom: 0;
  z-index: 1;
}
@mixin tooltipicongry {
  i {
    width: 16px;
    height: 16px;
    font-size: 14px;
    // border: 1px solid;
    border-radius: 50%;
    padding: 2px 5px;
    text-align: center;
    display: inline-block;
    vertical-align: text-top;
    margin: 0 0 0 10px;
    color: $tootipgray;
    &:hover {
      // color: $white;
      // background: $gray;
      // font-size: 15px;
    }
  }
}

@mixin selectmember {
  input {
    display: none;
  }
  label {
    height: 55px;
    background-position: 30px -44px;
    background-repeat: no-repeat;
    font-weight: bold;
    text-align: center;
    margin: 0 auto;
    width: 100%;
    padding: 14px 0 0 0;
    border-radius: 5px;
    border: 1px solid $dugong;
    color: $spinelblack;
    &:hover {
      color: $blue;
      border: 1px solid $blue;
      background-position: 30px 5px;
    }
  }
  input:checked + label {
    background-position: 30px -92px;
  }
}
