.contactdetails {
  padding: 20px;
  background: $white;
  box-shadow: 0px 3px 14px var(--aqua-1);
  border-radius: 25px;
  margin-top: 30px;
  margin-bottom: 30px;

  .custome-input,
  .custome-input-date,
  .custome-select {
    padding: 10px 0;
  }

  .custome-select, .custome-input{
    .MuiFormControl-root{
      .MuiInput-root{
        border: 1px solid #c9c9c9;
        border-radius: 50px;
        background-color: var(--aqua-2);
        &:before{
          border-bottom: 0px !important;
        }
        // &:hover{
        //   border-color: var(--aqua-9);
        // }
      }
      .MuiInput-root.Mui-focused{
        border: 1px solid var(--aqua-9);
      }
    }
  }
  .custome-input{
    label{
      padding-left: 10px;
    }
    input{
      padding-left: 9px;
      padding-right: 6px;
      border-radius: 50px;
      color: var(--aqua-9);
    }
  }
  .Residentialtitle {
    margin: 30px 0px;
    font-weight: bold;

    label {
      font-size: 16px !important;
    }

    @include checkboxcustom;
  }

  .contactborder {
    border-bottom: 1px solid var(--gray-2);
    
  }

  .contacttitle {
    border-bottom: 1px solid var(--gray-2);
    color: $lightblack;
    margin-bottom: 10px;

    span {
      font-size: 18px;
      font-weight: bold;
    }

    p {
      font-size: 14px;
      color: $slategray;
    }
  }
  input[type="radio"]{
    margin-right: 5px;
  }

  .custome-select .MuiSelect-selectMenu{
    background: transparent;
  }
}

.otherdetails {
  margin-bottom: 50px;
}

.contactotherdet {
  h2{
    color: var(--aqua-9);
  }
  
  padding: 15px 0;
  p {
    font-size: 14px;
    font-weight: 400;
    padding: 10px 20px 10px;
    color: var(--aqua-1);
  }

  .contactyesnobtn {
    // padding-left: 35px;
    // padding: 20px 0;
    // text-align: right;
  }
  // input{
  //   accent-color: var(--aqua-9);
    
  // }
 
}

.processmodal {
  margin: 15px !important;

  .modal-body {
    padding: 0 1rem;
  }

  .modal-header {
    border: none;
    padding: 0 1rem;

    button {
      outline: none;
      border: none;
    }

    .modaltitle {
      text-align: left;

      span {
        font-size: 18px;
        font-weight: bold;
      }

      p {
        font-size: 14px;
      }
    }
  }

  .modal-content {
    border: none;
  }

  .modalfrm {
    position: relative;

    .custome-input {
      margin-top: 15px;
    }

    input {
      width: 100%;
      padding: 15px 0 !important;
    }

    span {
      color: $grass;
      position: absolute;
      right: 0;
      bottom: 76px;
    }

    button,
    button:focus {
      background: $blue;
      color: $white;
      margin-top: 30px;
      padding: 8px 40px;
      outline: none;
      border: none;
    }
  }
}

.dot {
  height: 30px;
  width: 150px;
  color: rgba(0, 230, 64, 1);
  background-color: transparent !important;

  i {
    width: 10px !important;
    margin: 1px 5px 0 5px !important;
    height: 20px !important;
  }

  span {
    margin: 0 5px !important;
    
  }
}
// .form-check-inline .form-check-input{
//   accent-color:var(--aqua-1);
// }

#Edelweiss\ Member\ Yes, #Yes{
  border:2px solid white;
  box-shadow:0 0 0 1px var(--coral-4);
  appearance:none;
  border-radius:50%;
  width:12px;
  height:12px;
  background-color:#fff;
  transition:all ease-in 0.2s;

}
#Edelweiss\ Member\ Yes:checked, #Yes:checked{
  background-color:var(--aqua-1);
  box-shadow:0 0 0 1px var(--aqua-1);
}
#Edelweiss\ Member\ No, #No{
  border:2px solid white;
  box-shadow:0 0 0 1px var(--coral-4);
  appearance:none;
  border-radius:50%;
  width:12px;
  height:12px;
  background-color:#fff;
  transition:all ease-in 0.2s;

}
#Edelweiss\ Member\ No:checked, #No:checked{
  background-color:var(--aqua-1);
  box-shadow:0 0 0 1px var(--aqua-1);
}
