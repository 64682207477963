.modal {
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: scroll !important;
}

.pedmodal {
  height: 600px !important;
  overflow-x: hidden !important;
  overflow-y: scroll !important;
}

.addonspopup {
  &::-webkit-scrollbar {
    display: none;
  }
}
