.quickquotetitle {
  padding: 30px 0 0;
  font-weight: bold;

  span {
    font-size: 18px;
    display: inline-block;

    &:first-child {
      text-align: left;
      color: $blue;
    }

    &:last-child {
      text-align: right;
      color: $mengo;
      float: right;
    }
  }
}

.quickquotemanin {
  @include boxshow;
  margin-bottom: 150px;

  .quickquotheader {
    border-bottom: 1px solid rgba($dugong, 0.3);
    padding-bottom: 20px;

    .insuredhedtitle {
      font-size: 20px;
      font-weight: bold;
      color: $lightblack;
    }

    .insuredright {
      text-align: right;

      span {
        color: $gray;
        font-size: 14px;
        @include tooltipicongry;
      }
      .active {
        font-weight: bold;
        color: $lightblack;
      }
      // b {
      //   color: $lightblack;
      //   font-size: 14px;
      //   @include tooltipicongry;

      //   i {
      //     font-weight: normal;
      //   }
      // }

      .custom-switch {
        display: inline-block;
        margin: 0 10px 0 20px;
        // cursor: pointer;

        .custom-control-input:checked ~ .custom-control-label::before {
          background-color: $pink;
          border-color: $pink;
        }

        .custom-control-input:focus:not(:checked)
          ~ .custom-control-label::before {
          border-color: $blue;
          background-color: $blue;
        }

        .custom-control-label {
          &::after {
            background-color: $white;
          }

          &::before {
            background-color: $blue;
            border-color: $blue;
          }
        }

        .custom-control-input:focus ~ .custom-control-label::before {
          box-shadow: none;
        }
      }
    }
  }

  .insureddetails {
    .insuredtitle {
      color: $lightblack;
      font-size: 15px;
      font-weight: bold;
      text-align: left;
      padding-top: 60px;
      padding-bottom: 20px;

      span {
        &:last-child {
          font-size: 16px;
          text-align: right;
          color: $carbon;
          float: right;
        }
      }
    }

    .memberbox {
      margin-bottom: 20px;
      @include selectmember;
    }

    .husband {
      label {
        background-image: url(../../../Images/husband.png);
      }
    }

    .spouse {
      label {
        background-image: url(../../../Images/girls.png);
      }
    }

    .kids {
      position: relative;

      label {
        background-image: url(../../../Images/kids.png);
      }
    }

    .couterkids {
      position: absolute;
      right: 8px;
      top: 16px;

      span {
        border-radius: 5px;
        color: $blue;
        font-size: 16px;
        border: 1px solid;
        padding: 0px 7px;
        font-weight: bold;

        &:nth-child(2) {
          color: $lightblack;
          border: none;
        }
      }
    }

    .custome-input-date {
      border: 1px solid $deyouk;
      border-radius: 5px;
      padding: 5px 20px 0;

      label {
        color: $lightblack;
        font-weight: bold;
        // top: -6px;
      }

      .MuiFormLabel-root.Mui-focused {
        top: 0px;
      }

      .MuiInput-underline {
        &::before {
          border-bottom: none;
        }

        input {
          font-weight: bold;
        }
      }
    }

    .rangeslider {
      input {
        width: 100%;
        background: $blue !important;
      }

      .range-field {
        span {
          font-size: 12px;
          color: $lightblack;
          float: left;

          &:last-child {
            text-align: right;
            float: right;
          }
        }
      }
    }
  }

  .floaterdetails {
    margin: 30px 0;

    .quickquoteselect {
      select {
        border: 1px solid $deyouk;
        border-radius: 5px;
        padding: 15px 15px;
        height: auto;
        font-weight: bold;
      }

      .form-control:focus {
        border: 1px solid $blue;
        box-shadow: none;
      }
    }

    .floaterrangetitle {
      color: $carbon;
      font-size: 12px;
      font-weight: bold;
      text-align: left;

      span {
        &:last-child {
          font-size: 14px;
          text-align: right;
          float: right;
        }
      }
    }

    .floaterrangeslider {
      input {
        width: 100%;
        background: $blue !important;
      }
      .custome-select {
        label + .MuiInput-formControl {
          margin-top: 0px;
        }
      }
      .range-field {
        line-height: 15px;

        span {
          font-size: 12px;
          color: $lightblack;
          float: left;

          &:last-child {
            text-align: right;
            float: right;
          }
        }
      }
    }

    .floaterdate {
      border: 1px solid $deyouk;
      border-radius: 5px;
      padding: 7px 0px 0;

      .MuiFormLabel-root.Mui-focused {
        top: 0px;
      }

      label + .MuiInput-formControl {
        margin-top: 14px;
      }

      label {
        color: $lightblack;
        font-weight: bold;
        position: absolute;
        // top: -8px;
      }

      .MuiInput-underline {
        &::before {
          border-bottom: none;
        }

        input {
          font-weight: bold;
        }
      }
    }

    .floatercheckbox {
      padding-top: 15px;

      label {
        color: $seblack;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 20px 0;
      }

      .custom-control-label::after,
      .custom-control-label::before {
        left: 0;
      }

      .custom-control-input:checked ~ .custom-control-label {
        color: $blue;

        &::before {
          color: $white;
          border-color: $pink;
          background-color: $pink;
        }
      }

      .custom-control-input:focus ~ .custom-control-label {
        &::before {
          box-shadow: none;
        }
      }

      .custom-control-input:focus:not(:checked) ~ .custom-control-label {
        &::before {
          border-color: $pink;
        }
      }
    }
  }

  .addmorbtn {
    button {
      color: $pink;
      border: 1px solid $pink;
      border-radius: 5px;
      background: $white;
      width: 100%;
      text-transform: uppercase;
      outline: none;
    }
  }
}

.queckquotfooter {
  @include fixedfooter;
  position: fixed;

  button,
  .button:focus {
    color: $white;
    background: $pink;
    border-radius: 5px;
    font-size: 14px;
    padding: 12px 15px;
    border: none;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    outline: none;
    text-transform: uppercase;
    font-size: 14px;
  }

  .skipbtn {
    button {
      border: 1px solid $pink;
      background: transparent;
      color: $pink;
      font-weight: bold;
      font-size: 14px;

      &:hover {
        background: $pink;
        color: $white;
      }
    }
  }
}

.floatercheckbox {
  .custom-control-input:checked ~ .custom-control-label {
    color: $blue;
    &::before {
      color: $white;
      border-color: $pink;
      background-color: $pink;
    }
  }
}
