.MuiMenuItem-root {
  font-size: 12px !important;
}

.addselectbox {
  margin-top: -15px;

  .custome-select .MuiSelect-select:focus{
    background-color: white !important;
  }
}

.MemberDetailsbox {
  padding: 30px;
  background: $white;
  box-shadow: 0px 3px 14px var(--aqua-1);
  border-radius: 25px;
  margin-top: 60px;
  margin-bottom: 120px;

  .Membertitlebox {
    border-bottom: 1px solid var(--gray-2);
    padding: 20px 0 10px;

    select,
    select:focus,
    input {
      width: 100%;
      border: none;
      border-bottom: 1px solid $lightblack;
      padding: 4px 0;
      color: $seblack;
      font-size: 14px;
    }

    select,
    select:focus {
      background-image: linear-gradient(45deg, transparent 50%, $seblack 60%),
        linear-gradient(135deg, $seblack 40%, transparent 50%) !important;
      background-position: calc(100% - 6px) 14px, calc(100% - 0px) 14px, 100% 0;
      background-size: 6px 6px, 6px 6px;
      background-repeat: no-repeat;
      -webkit-appearance: none;
      -moz-appearance: none;
    }

    .membertopselect {
      position: relative;
      top: 5px;

      .custome-select{
        .MuiFormLabel-root{
          color: var(--aqua-9) !important;
        }
        
        .MuiFormControl-root .MuiInputBase-root.Mui-focused .MuiSelect-select{
          background-color: white;
        }

        label + .MuiInput-formControl{
          color: var(--aqua-9);
        }

        .MuiInputBase-root.Mui-disabled{
          color: rgba(0,0,0,0.38);
        }
      }
    }
  }

  .Memberlistingbox {
    border-bottom: 1px solid $skies;
    padding: 20px 0;

    select,
    select:focus {
      width: 100%;
      border: none;
      border-bottom: 1px solid $lightblack;
      padding: 4px 0;
      color: $seblack;
      font-size: 14px;
      background-image: linear-gradient(45deg, transparent 50%, $seblack 60%),
        linear-gradient(135deg, $seblack 40%, transparent 50%) !important;
      background-position: calc(100% - 6px) 14px, calc(100% - 0px) 14px, 100% 0;
      background-size: 6px 6px, 6px 6px;
      background-repeat: no-repeat;
      -webkit-appearance: none;
      -moz-appearance: none;
    }

    .custome-input-date {
      input {
        font-weight: bold;
      }

      .input {
        border-bottom: 1px solid $lightblack;
        width: 100%;
        font-size: 14px;
        padding-right: 20px;
      }

      .MuiIconButton-root {
        display: none;
      }

      .MuiInputBase-input{
        background-color: transparent;
        border-radius: 50px;
        color: var(--aqua-9);
      }

      .MuiTextField-root .MuiOutlinedInput-adornedEnd{	
        border-radius: 50px;	
        background-color: var(--aqua-2);	
      }

      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {	
        border: 1px solid var(--aqua-9);	
      }

      .MuiFormHelperText-root.Mui-error{
        color: black !important;
      }
      .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
        border-color: var(--aqua-9) !important;
      }

    }

    .close-add-member {
      position: absolute;
      top: 4px;
      right: -15px;
      color: var(--coral-4) !important;
    }

    .custom-checkbox {
      padding-top: 15px;

      label {
        color: var(--aqua-1) !important;
        font-size: 14px;
        font-weight: 500;
      }

      .custom-control-input:checked ~ .custom-control-label {
        color: $pink;

        &::before {
          color: $white;
          border-color: var(--aqua-7) !important;
          background-color: var(--aqua-7) !important;
        }
      }

      .custom-control-input:focus ~ .custom-control-label {
        &::before {
          box-shadow: none;
        }
      }

      .custom-control-input:focus:not(:checked) ~ .custom-control-label {
        &::before {
          border-color: var(--coral-4) !important;
        }
      }
    }
  }

  .MemberTitle {
    span {
      color: var(--aqua-9) !important;
      font-size: 24px;
      font-weight: bold;
    }

    p {
      color: var(--aqua-1);
      opacity: 0.5;
      font-size: 14px;
    }
  }

  .addmembertitle {
    font-size: 16px;
    color: $blue;
    font-weight: bold;
    margin-top: 30px;
  }

  .addmemberbtn {
    text-align: left;

    button {
      background: transparent;
      font-size: 14px;
      color: var(--aqua-9);
      margin: 20px 0 0 0;
      font-weight: bold;
      border: 1px solid var(--aqua-9);
      outline: none;
      border-radius: 50px;

      &:hover {
        background: var(--aqua-9) !important;
        color: $white;
      }
    }
  }
}

.memberbtnmain {
  margin-top: 40px;
  margin-bottom: 40px;
}

.memberbtn {
  min-width: 110px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  outline: none;
  width: 49%;
  margin-bottom: 30px;

  &:hover {
    background: $pink;
    color: $white;
  }

  &:focus {
    outline: none;
  }
}

.cancelbtn {
  color:var(--aqua-9);
  background: transparent;
  width: 49%;
  font-weight: bold;
  border-radius: 50px !important;
}

.quotebtn {
  background: var(--aqua-9) !important;
  color: $white;
  margin-left: 5px;
  padding: 11px 0;
  font-weight: bold;
  border-radius: 50px !important;

  &:hover {
    filter: brightness(80%) !important;
  }
}

.memberfooter {
  @include fixedfooter;
  position: fixed;
  padding: 20px;

  button {
    margin-bottom: 0;
  }
}

.modalmain {
  .MemberDetailsbox {
    box-shadow: none;
    margin-top: 0;
    margin-bottom: 0;
    padding: 30px 0 0;
  }

  .custome-input-date {
    padding: 0 0 10px;
  }

  .editdetailsbtn {
    text-align: right;
    border-top: 1px solid $skies;
    padding: 30px 0 20px;
    margin-top: 20px;
    position: fixed;

    button {
      text-transform: uppercase;
      width: 150px;
    }
  }

  .Memberlistingbox {
    border-bottom: 1px solid $skies;
    padding: 0;

    .custom-checkbox {
      padding-top: 30px;
    }
  }

  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .modal {
      top: -19%;
    }
  }

  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
    .modal {
      top: -19%;
    }
  }
}

.close-add-member {
  background: transparent;
  outline: none;
  border: 0;

  &:focus {
    outline: none;
  }
}

.invalid-feedback {
  bottom: 0;
  color: black !important;
}

.valid-feedback {
  bottom: 0;
  color: black;
}

.range-feedback {
  bottom: 0;
  color: var(--aqua-1);
  font-size: small;
  .p{
    font-size: small ;
  }
}
.invalid-feedback-resend-otp {
  bottom: 0;
  color: var(--aqua-9) !important;
}

.editmemberpopup {
  z-index: 9999;
  position: absolute;
  .modal-dialog {
    margin: 0;
  }
  .editdetailsbtn {
    .memberbtn {
      padding: 10px 15px;
    }
  }
  .Membertitlebox {
    padding: 0;
    .MemberTitle {
      span {
        font-size: 20px;
      }
      p {
        font-size: 12px;
        opacity: 1;
      }
    }
  }
  .MemberDetailsbox {
    margin-top: 50px;
    margin-bottom: 60px;
  }
}

.MuiSnackbar-anchorOriginTopRight{
  .MuiAlert-filledSuccess{
    border-radius: 25px;
    background-color: var(--aqua-9);
  }
  .MuiAlert-filledError{
    border-radius: 25px;
  }
}